import { ActionType, createAsyncAction } from 'typesafe-actions'
import { Failure, Request, Success } from './types'
import { ChatState } from '../../types'

export const transferChatToOperatorAction = createAsyncAction(
  '@chat/TRANSFER_CHAT_TO_OPERATOR_REQUEST',
  '@chat/TRANSFER_CHAT_TO_OPERATOR_SUCCESS',
  '@chat/TRANSFER_CHAT_TO_OPERATOR_FAILURE'
)<Request, Success, Failure>()

const request = (state: ChatState): ChatState => ({
  ...state,
  isLoadingTransferChatToOperator: true
})

const success = (state: ChatState, { payload }: ActionType<typeof transferChatToOperatorAction.success>): ChatState => {
  const { transferedChat } = payload

  switch (transferedChat.status) {
    case 'open':
      return {
        ...state,
        openChats: state.openChats.filter(chat => chat.id !== transferedChat.id),
        inProgressChats: state.inProgressChats.filter(chat => chat.id !== transferedChat.id),
        finishedChats: state.finishedChats.filter(chat => chat.id !== transferedChat.id),
        isLoadingTransferChatToOperator: false,
        isSuccessTransferChatToOperator: true
      }
    case 'in_progress':
      return {
        ...state,
        openChats: state.openChats.filter(chat => chat.id !== transferedChat.id),
        inProgressChats: state.inProgressChats.filter(chat => chat.id !== transferedChat.id),
        finishedChats: state.finishedChats.filter(chat => chat.id !== transferedChat.id),
        isLoadingTransferChatToOperator: false,
        isSuccessTransferChatToOperator: true
      }
    default:
      return {
        ...state,
        openChats: state.openChats.filter(chat => chat.id !== transferedChat.id),
        inProgressChats: state.inProgressChats.filter(chat => chat.id !== transferedChat.id),
        finishedChats: state.finishedChats.filter(chat => chat.id !== transferedChat.id),
        isLoadingTransferChatToOperator: false,
        isSuccessTransferChatToOperator: true
      }
  }
}

const failure = (state: ChatState): ChatState => ({
  ...state,
  isLoadingTransferChatToOperator: false,
  isErrorOnTransferChatToOperator: false
})

export const transferChatToOperatorHandle = { asyncAction: transferChatToOperatorAction, request, success, failure }
