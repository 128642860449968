import { ActionType, createAction } from 'typesafe-actions'
import { ChatState } from '../../types'
import { DTO } from './types'

export const setActiveChatAction = createAction('@chat/SET_ACTIVE_CHAT')<DTO>()

const success = (state: ChatState, action: ActionType<typeof setActiveChatAction>): ChatState => ({
  ...state,
  activeChat: action.payload,
  infiniteScrollBottomHack: !state.infiniteScrollBottomHack
})

export const setActiveChatHandle = { asyncAction: setActiveChatAction, success }
