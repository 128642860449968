import { ActionType, createAction } from 'typesafe-actions'
import { ChatState } from '../../types'
import { DTO } from './types'

export const updateActiveChatAction = createAction('@chat/UPDATE_ACTIVE_CHAT')<DTO>()

const success = (state: ChatState, action: ActionType<typeof updateActiveChatAction>): ChatState => {
  delete action.payload.messages
  return {
    ...state,
    activeChat: { ...state.activeChat, ...action.payload },
    infiniteScrollBottomHack: !state.infiniteScrollBottomHack
  }
}

export const updateActiveChatHandle = { asyncAction: updateActiveChatAction, success }
