import { createReducer } from 'typesafe-actions'
import { CompanyAction, CompanyState } from './types'
import { loadCompanyPhonesHandle, setSelectedCompanyHandle } from './handles'

const initialState: CompanyState = {
  companyPhones: [],
  isErrorOnLoadingCompanyPhones: false,
  isSuccessOnLoadingCompanyPhones: false,
  isLoadingCompanyPhones: false,
  selectedCompany: null,
  isErrorOnLoadingSetSelectedCompany: false,
  isLoadingSetSelectedCompany: false,
  isSuccessOnLoadingSetSelectedCompany: false
}

export const companyReducer = createReducer<CompanyState, CompanyAction>(initialState)
  .handleAction(loadCompanyPhonesHandle.asyncAction.request, loadCompanyPhonesHandle.request)
  .handleAction(loadCompanyPhonesHandle.asyncAction.success, loadCompanyPhonesHandle.success)
  .handleAction(loadCompanyPhonesHandle.asyncAction.failure, loadCompanyPhonesHandle.failure)
  .handleAction(setSelectedCompanyHandle.asyncAction.request, setSelectedCompanyHandle.request)
  .handleAction(setSelectedCompanyHandle.asyncAction.success, setSelectedCompanyHandle.success)
  .handleAction(setSelectedCompanyHandle.asyncAction.failure, setSelectedCompanyHandle.failure)
