import { ActionType, createAsyncAction } from 'typesafe-actions'
import { Failure, Request, Success } from './types'
import { CompanyState } from '../../types'

export const setSelectedCompanyAction = createAsyncAction(
  '@company/SET_SELECTED_COMPANY_REQUEST',
  '@company/SET_SELECTED_COMPANY_SUCCESS',
  '@company/SET_SELECTED_COMPANY_FAILURE'
)<Request, Success, Failure>()

const request = (state: CompanyState): CompanyState => ({
  ...state,
  isLoadingSetSelectedCompany: true,
  isErrorOnLoadingSetSelectedCompany: false,
  isSuccessOnLoadingSetSelectedCompany: false
})

const success = (state: CompanyState, action: ActionType<typeof setSelectedCompanyAction.success>): CompanyState => ({
  ...state,
  isLoadingSetSelectedCompany: false,
  isErrorOnLoadingSetSelectedCompany: false,
  isSuccessOnLoadingSetSelectedCompany: true,
  selectedCompany: action.payload.selectedCompany
})

const failure = (state: CompanyState): CompanyState => ({
  ...state,
  isLoadingSetSelectedCompany: false,
  isSuccessOnLoadingSetSelectedCompany: false,
  isErrorOnLoadingSetSelectedCompany: true
})

export const setSelectedCompanyHandle = { asyncAction: setSelectedCompanyAction, request, success, failure }
