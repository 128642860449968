import { createReducer } from 'typesafe-actions'
import { DepartmentAction, DepartmentState } from './types'
import { selectDeparmentHandle, clearSelectDeparmentHandle } from './handles'

const initialState: DepartmentState = {
  selectedDepartment: null,
  departmentCheck: null,
  isLoadingSelectDepartment: false,
  isErrorSelectDepartment: false,
  isSuccessSelectDepartment: false
}

export const departmentReducer = createReducer<DepartmentState, DepartmentAction>(initialState)
  .handleAction(selectDeparmentHandle.asyncAction.request, selectDeparmentHandle.request)
  .handleAction(selectDeparmentHandle.asyncAction.success, selectDeparmentHandle.success)
  .handleAction(selectDeparmentHandle.asyncAction.failure, selectDeparmentHandle.failure)
  .handleAction(clearSelectDeparmentHandle.asyncAction, clearSelectDeparmentHandle.success)
