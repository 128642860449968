/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import { _fireStore } from '@/helpers/firebase'
import { InstanceModel } from '@/models/instance.model'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { put } from 'redux-saga/effects'
import { loadCompanyPhonesAction } from '../../handles'

export function* loadCompanyPhonesSaga(action: ReturnType<typeof loadCompanyPhonesAction.request>) {
  const { userAuthenticated } = action.payload
  try {
    const { role, phoneNumbers } = userAuthenticated
    if ((role.includes('ADMIN') && role.includes('OPERATOR')) || role.includes('ADMIN')) {
      const instances: InstanceModel[] = yield laodInstances(userAuthenticated.ownerId, true)
      const phones = instances.map(instance => instance.phone)
      return yield put(loadCompanyPhonesAction.success({ companyPhones: phones }))
    }

    if (role.includes('OPERATOR')) {
      const phones = phoneNumbers.map(phone => phone)
      return yield put(loadCompanyPhonesAction.success({ companyPhones: phones }))
    }

    yield put(loadCompanyPhonesAction.success({ companyPhones: [] }))
  } catch (error) {
    yield put(loadCompanyPhonesAction.failure(error))
  }
}

async function laodInstances(ownerId: string, connected: boolean) {
  const _collection = collection(_fireStore, 'connected-instances')
  const _query = query(_collection, where('ownerId', '==', ownerId), where('connected', '==', connected))
  const querySnapshot = await getDocs(_query)
  if (querySnapshot.empty) return [] as InstanceModel[]
  return querySnapshot.docs.map(doc => doc.data() as InstanceModel)
}
