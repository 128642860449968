import { ActionType, createAction } from 'typesafe-actions'
import { DTO } from './types'
import { ChatState } from '../../types'
import { addChatInChatsState } from '../helpers'

export const updateChatAction = createAction('@chat/UPDATE_CHAT')<DTO>()

const success = (state: ChatState, action: ActionType<typeof updateChatAction>): ChatState => {
  let openChatsUpdated = []
  let inProgressChatsUpdated = []
  let finishedChatsUpdated = []
  switch (action.payload.chat.status) {
    case 'open':
      inProgressChatsUpdated = state.inProgressChats.filter(chat => chat.id !== action.payload.chat.id)
      finishedChatsUpdated = state.finishedChats.filter(chat => chat.id !== action.payload.chat.id)
      openChatsUpdated = addChatInChatsState(state.openChats, action.payload.chat)
      return {
        ...state,
        openChats: openChatsUpdated,
        inProgressChats: inProgressChatsUpdated,
        finishedChats: finishedChatsUpdated
      }
    case 'in_progress':
      openChatsUpdated = state.openChats.filter(chat => chat.id !== action.payload.chat.id)
      finishedChatsUpdated = state.finishedChats.filter(chat => chat.id !== action.payload.chat.id)
      inProgressChatsUpdated = addChatInChatsState(state.inProgressChats, action.payload.chat)
      return {
        ...state,
        openChats: openChatsUpdated,
        inProgressChats: inProgressChatsUpdated,
        finishedChats: finishedChatsUpdated
      }
    default:
      openChatsUpdated = state.openChats.filter(chat => chat.id !== action.payload.chat.id)
      inProgressChatsUpdated = state.inProgressChats.filter(chat => chat.id !== action.payload.chat.id)
      finishedChatsUpdated = addChatInChatsState(state.finishedChats, action.payload.chat)
      return {
        ...state,
        openChats: openChatsUpdated,
        inProgressChats: inProgressChatsUpdated,
        finishedChats: finishedChatsUpdated
      }
  }
}

export const updateChatHandle = { asyncAction: updateChatAction, success }
