import { ActionType, createAsyncAction } from 'typesafe-actions'
import { Failure, Request, Success } from './types'
import { ChatState } from '../../types'

export const fetchMoreChatsAction = createAsyncAction(
  '@chat/FETCH_MORE_CHATS_REQUEST',
  '@chat/FETCH_MORE_CHATS_SUCCESS',
  '@chat/FETCH_MORE_CHATS_FAILURE'
)<Request, Success, Failure>()

const request = (state: ChatState, action: ActionType<typeof fetchMoreChatsAction.request>): ChatState => ({
  ...state,
  isLoadingReadMessagesFromChat: true,
  isErrorOnReadMessagesFromChat: false
})

const success = (state: ChatState, { payload }: ActionType<typeof fetchMoreChatsAction.success>): ChatState => {
  const [firstStatus] = payload.status
  switch (firstStatus) {
    case 'open':
      return {
        ...state,
        openChats: [...state.openChats, ...payload.chats]
      }
    case 'in_progress':
      return {
        ...state,
        inProgressChats: [...state.inProgressChats, ...payload.chats]
      }
    default:
      return {
        ...state,
        finishedChats: [...state.finishedChats, ...payload.chats]
      }
  }
}

const failure = (state: ChatState, action: ActionType<typeof fetchMoreChatsAction.failure>): ChatState => ({
  ...state,
  isLoadingReadMessagesFromChat: true,
  isErrorOnReadMessagesFromChat: false
})

export const fetchMoreChatsHandle = { asyncAction: fetchMoreChatsAction, request, success, failure }
