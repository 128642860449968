import { createAction } from 'typesafe-actions'
import { DepartmentState } from '../../types'

export const clearSelectDepartmentAction = createAction('@department/CLEAR_SELECT_DEPARTMENT_REQUEST')<void>()

const success = (state: DepartmentState): DepartmentState => {
  return {
    ...state,
    departmentCheck: null,
    selectedDepartment: null
  }
}

export const clearSelectDeparmentHandle = {
  asyncAction: clearSelectDepartmentAction,
  success
}
