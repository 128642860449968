import { ActionType, createAction } from 'typesafe-actions'
import { ChatState } from '../../types'
import { DTO } from './types'
import { updateChatInChatsState } from '../helpers'

export const setActiveChatMessagesAction = createAction('@chat/SET_ACTIVE_CHAT_MESSAGES')<DTO>()

const success = (state: ChatState, action: ActionType<typeof setActiveChatMessagesAction>): ChatState => {
  const newActiveChat = {
    ...state.activeChat,
    messages: [...state.activeChat.messages, ...action.payload]
  }
  return {
    ...state,
    activeChat: newActiveChat,
    // TODO: check why we is updateChatInChatsState
    chats: updateChatInChatsState(newActiveChat, state.chats)
  }
}

export const setActiveChatMessagesHandle = { asyncAction: setActiveChatMessagesAction, success }
