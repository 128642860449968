/* eslint-disable */
import React, { Suspense } from 'react'
import { Routes as SwitchRoute, Route, Navigate, useParams } from 'react-router-dom'

// import routes
import { authProtectedRoutes, publicRoutes } from './routes'

// import layouts
import NonAuthLayout from '../layouts/NonAuth'
import AuthLayout from '../layouts/AuthLayout/'
import { useCompany } from '@/redux/company'

const AuthProtected = props => {
  /*
    Navigate is un-auth access protected routes via url
  */
  const { companyId } = useParams()

  if (props.isAuthProtected && !localStorage.getItem('devchat_app_userAuthenticated')) {
    if (props?.selectedCompany || companyId) {
      return (
        <Navigate
          to={{ pathname: `/sign-in/${props.selectedCompany || companyId}`, state: { from: props.location } }}
        />
      )
    } else {
      return <Navigate to={{ pathname: `/sign-in/:companyId`, state: { from: props.location } }} />
    }
  }

  return <>{props.children}</>
}

/**
 * Main Route component
 */
const Routes = () => {
  const {
    companyState: { selectedCompany }
  } = useCompany()

  return (
    // rendering the router with layout
    <React.Fragment>
      <Suspense fallback={<div></div>}>
        <SwitchRoute>
          {/* public routes */}
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              exact={route.exact}
              layout={NonAuthLayout}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {/* private/auth protected routes */}
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              layout={AuthLayout}
              element={
                <AuthProtected isAuthProtected={true} selectedCompany={selectedCompany}>
                  <AuthLayout>{route.component}</AuthLayout>
                </AuthProtected>
              }
              key={idx}
              isAuthProtected={true}
            />
          ))}
        </SwitchRoute>
      </Suspense>
    </React.Fragment>
  )
}

export default Routes
