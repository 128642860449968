import { _fireStore } from '@/helpers/firebase'
import { DocumentData, Query, collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore'

export function lastMessagesByChatQuery(chatId: string, ownerId: string, queryLimit: number) {
  return query(
    collection(_fireStore, `messages`),
    where('ownerId', '==', ownerId),
    where('chatId', '==', chatId),
    orderBy('createdAt', 'desc'),
    limit(queryLimit)
  )
}

export async function countDocumentsInQuery(query: Query<DocumentData>) {
  try {
    const snapshot = await getDocs(query)
    return snapshot.size
  } catch (error) {
    console.error('Error on count docs:', error)
    throw error
  }
}
