import React from 'react'
import { Navigate } from 'react-router-dom'

// lazy load all the views
const SignIn = React.lazy(() => import('../pages/sign-in'))
const ChatPage = React.lazy(() => import('../pages/chat-page'))
const SelectDepartment = React.lazy(() => import('../pages/select-department'))
// declare all routes
// TODO: improve this
const authProtectedRoutes = [
  { path: '/chat-page/:companyId', component: <ChatPage /> },
  {
    path: '/',
    exact: true,
    component: <Navigate to="/chat-page/:companyId" />
  },
  { path: '*', component: <Navigate to="/chat-page" /> }
]

const publicRoutes = [
  { path: '/sign-in/:companyId', exact: true, component: <SignIn /> },
  { path: '/select-department/:companyId', exact: true, component: <SelectDepartment /> }
]

export { authProtectedRoutes, publicRoutes }
