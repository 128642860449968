import { api } from '@/services/api'
import { put } from 'redux-saga/effects'
import { loadChatsBySearchAction } from '../../handles/loadChatsBySearchAction'
import { setHasMoreChatsAction } from '../../actions'

export function* loadChatsBySearchSaga(action: ReturnType<typeof loadChatsBySearchAction.request>) {
  const { status, ownerId, operatorId, campaignIds, departmentId, search } = action.payload
  try {
    const dto: any = { status, ownerId, operatorId, campaignIds, departmentId, search }
    const response = yield api.post('/get-chats-by-search', dto)
    yield put(setHasMoreChatsAction({ state: false, status }))
    yield put(loadChatsBySearchAction.success({ chats: response.data.searchedChats || [], status }))
  } catch (error) {
    yield put(setHasMoreChatsAction({ state: false, status }))
    yield put(loadChatsBySearchAction.failure(error))
  }
}
