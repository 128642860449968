import { ActionType, createAsyncAction } from 'typesafe-actions'
import { Failure, Request, Success } from './types'
import { CompanyState } from '../../types'

export const loadCompanyPhonesAction = createAsyncAction(
  '@company/LOAD_COMPANY_PHONES_REQUEST',
  '@company/LOAD_COMPANY_PHONES_SUCCESS',
  '@company/LOAD_COMPANY_PHONES_FAILURE'
)<Request, Success, Failure>()

const request = (state: CompanyState): CompanyState => ({
  ...state,
  isLoadingCompanyPhones: true,
  isErrorOnLoadingCompanyPhones: false,
  isSuccessOnLoadingCompanyPhones: false
})

const success = (state: CompanyState, action: ActionType<typeof loadCompanyPhonesAction.success>): CompanyState => ({
  ...state,
  isLoadingCompanyPhones: false,
  isErrorOnLoadingCompanyPhones: false,
  isSuccessOnLoadingCompanyPhones: true,
  companyPhones: action.payload.companyPhones
})

const failure = (state: CompanyState): CompanyState => ({
  ...state,
  isLoadingCompanyPhones: false,
  isSuccessOnLoadingCompanyPhones: false,
  isErrorOnLoadingCompanyPhones: true
})

export const loadCompanyPhonesHandle = { asyncAction: loadCompanyPhonesAction, request, success, failure }
