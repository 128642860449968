import { _fireBaseStorage } from '@/helpers/firebase'
import { deleteObject, ref } from 'firebase/storage'
import { put } from 'redux-saga/effects'
import { deleteStickerAction } from '../../handles'

export function* deleteStickerSaga(action: ReturnType<typeof deleteStickerAction.request>) {
  try {
    const { sticker, ownerId } = action.payload
    const filePath = `storage_to_${ownerId}/data/media/sticker/${sticker.name}`
    yield deleteFile(filePath)
    yield put(deleteStickerAction.success(sticker))
  } catch (error) {
    yield put(deleteStickerAction.failure({ isError: false }))
  }
}

async function deleteFile(filePath: string) {
  try {
    const stickerRef = ref(_fireBaseStorage, filePath)
    await deleteObject(stickerRef)
  } catch (error: any) {
    console.error('deleteFile error', error)
    throw error
  }
}
