import { ActionType, createAsyncAction } from 'typesafe-actions'
import { Failure, Request, Success } from './types'
import { ChatState } from '../../types'

export const answerChatAction = createAsyncAction(
  '@chat/ANSWER_CHAT_REQUEST',
  '@chat/ANSWER_CHAT_SUCCESS',
  '@chat/ANSWER_CHAT_FAILURE'
)<Request, Success, Failure>()

const request = (state: ChatState, action: ActionType<typeof answerChatAction.request>): ChatState => ({
  ...state,
  isLoadingAnswerChat: true,
  isErrorOnAnswerChat: false
})

const success = (state: ChatState, action: ActionType<typeof answerChatAction.success>): ChatState => ({
  ...state,
  isLoadingAnswerChat: false,
  isErrorOnAnswerChat: false,
  activeChat: action.payload
})
const failure = (state: ChatState, action: ActionType<typeof answerChatAction.failure>): ChatState => ({
  ...state,
  isLoadingAnswerChat: false,
  isErrorOnAnswerChat: true,
  // TODO: fill error
  activeChat: action.payload
})

export const answerChatHandle = { asyncAction: answerChatAction, request, success, failure }
