import { ActionType, createAsyncAction } from 'typesafe-actions'
import { Failure, Request, Success } from './types'
import { ChatState } from '../../types'
import { addUniqueMessages } from '../helpers'

export const loadMessagesByChatIdAction = createAsyncAction(
  '@chat/LOAD_MESSAGES_BY_CHAT_ID_REQUEST',
  '@chat/LOAD_MESSAGES_BY_CHAT_ID_SUCCESS',
  '@chat/LOAD_MESSAGES_BY_CHAT_ID_FAILURE'
)<Request, Success, Failure>()

const request = (state: ChatState, action: ActionType<typeof loadMessagesByChatIdAction.request>): ChatState => ({
  ...state,
  isLoadingOnLoadMessagesByChatId: true,
  isSuccessOnMessagesByChatId: false,
  isErrorOnMessagesByChatId: false,
  loadMessagesByChatIdError: null
})

const success = (state: ChatState, action: ActionType<typeof loadMessagesByChatIdAction.success>): ChatState => ({
  ...state,
  isLoadingOnLoadMessagesByChatId: false,
  isLoadingActiveChatMessagesSuccess: false,
  isSuccessOnMessagesByChatId: true,
  loadMessagesByChatIdError: null,
  activeChat: {
    ...state.activeChat,
    messages: addUniqueMessages([], action.payload)
  }
})

const failure = (state: ChatState, action: ActionType<typeof loadMessagesByChatIdAction.failure>): ChatState => ({
  ...state,
  isLoadingOnLoadMessagesByChatId: false,
  isSuccessOnMessagesByChatId: false,
  isLoadingActiveChatMessagesSuccess: false,
  loadMessagesByChatIdError: action.payload
})

export const loadMessagesByChatIdHandle = { asyncAction: loadMessagesByChatIdAction, request, success, failure }
