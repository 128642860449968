import { ActionType, createAsyncAction } from 'typesafe-actions'
import { Request, Success, Failure } from './types'
import { StickerState } from '../../types'

export const saveNewStickerAction = createAsyncAction(
  '@sticker/SAVE_STICKER_REQUEST',
  '@sticker/SAVE_STICKER_SUCCESS',
  '@sticker/SAVE_STICKER_FAILURE'
)<Request, Success, Failure>()

const request = (state: StickerState): StickerState => ({
  ...state,
  isLoadingSaveNewSticker: true
})

const success = (state: StickerState, action: ActionType<typeof saveNewStickerAction.success>): StickerState => ({
  ...state,
  stickers: [...state.stickers, action.payload],
  isLoadingSaveNewSticker: false,
  isSuccessOnLoadingSaveNewSticker: true
})

const failure = (state: StickerState): StickerState => ({
  ...state,
  isLoadingSaveNewSticker: false,
  isErrorOnSaveNewSticker: true
})

export const saveNewStickerHandle = { asyncAction: saveNewStickerAction, request, success, failure }
