import { setActiveChatMessagesAction } from '@/redux/chat/actions'
import { getDocs } from 'firebase/firestore'
import { put } from 'redux-saga/effects'
import { fetchMoreMessagesAsync, setHasMoreMessages } from '../../actions'
import { countDocumentsInQuery, hasLastMessageIntoCurrentMessages, lastMessagesByChatFromDateQuery } from './helpers'
import { MessageModel } from '@/models/message.model'

const MESSAGES_LIMIT = 12
const COUNT_QUERY_LIMIT = 13

export function* fetchMoreMessagesSaga(action: ReturnType<typeof fetchMoreMessagesAsync.request>) {
  yield put(setHasMoreMessages({ state: false }))
  try {
    const { lastMessage, chatId, ownerId } = action.payload
    const lastMessages = yield fetchLastMessagesFromMessage(lastMessage, chatId, ownerId)
    const hasMoreMessages = yield checkMoreMessagesAvailability(chatId, ownerId, lastMessage)
    yield put(
      setActiveChatMessagesAction(hasLastMessageIntoCurrentMessages(lastMessage, lastMessages) ? [] : lastMessages)
    )
    yield put(setHasMoreMessages({ state: hasMoreMessages }))
  } catch (error) {
    yield put(fetchMoreMessagesAsync.failure(error))
  }
}

async function fetchLastMessagesFromMessage(message: MessageModel, chatId: string, ownerId: string) {
  try {
    const fromDate = message.momment || message.createdAt
    const lastMessagesFromDateQuery = lastMessagesByChatFromDateQuery(chatId, ownerId, fromDate, MESSAGES_LIMIT)
    const lastMessagesFromDateSnapshot = await getDocs(lastMessagesFromDateQuery)
    const lastMessagesFromDate = lastMessagesFromDateSnapshot.docs.map(doc => doc.data())
    return lastMessagesFromDate
  } catch (error) {
    throw new Error(`Error on fetchLastMessagesFromMessage: ${error}`)
  }
}

// TODO: move to a generic helper, because we are using this on two places
function* checkMoreMessagesAvailability(chatId: string, ownerId: string, lastMessage: MessageModel) {
  const fromDate = lastMessage.momment || lastMessage.createdAt
  const lastMessagesFromDateQuery = lastMessagesByChatFromDateQuery(chatId, ownerId, fromDate, COUNT_QUERY_LIMIT)
  const documentsCount = yield countDocumentsInQuery(lastMessagesFromDateQuery)
  return documentsCount >= COUNT_QUERY_LIMIT
}
