export function formatPhoneNumber(phone: number | string) {
  if (!phone) return ''
  const phoneNumber = phone.toString()

  const regexWithNine = /^(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})$/
  const regexWithoutNine = /^(\d{2})(\d{2})(\d{4})(\d{4})$/

  if (regexWithNine.test(phoneNumber)) {
    return phoneNumber.replace(regexWithNine, '+$1 ($2) $3 $4-$5')
  } else if (regexWithoutNine.test(phoneNumber)) {
    return phoneNumber.replace(regexWithoutNine, '+$1 ($2) $3-$4')
  } else {
    console.log('Formato de número inválido')
    return phone
  }
}

export function removeSpecialCharacters(number: string) {
  return number.replace(/[^0-9]+/g, '')
}

// FIXME: this work only for Brazil
export function validateFormatFromNumber(number: string) {
  const cleanedNumber = removeSpecialCharacters(number)
  const pattern = /^55\d{10,11}$/
  return pattern.test(cleanedNumber)
}
