import { _fireStore } from '@/helpers/firebase'
import { Chat } from '@/redux/chat/types'
import { DocumentData, Query, collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore'
import { call, put } from 'redux-saga/effects'
import { loadChatsByStatusAction, setHasMoreChatsAction } from '../../actions'

const CHATS_LIMIT = 15
const COUNT_QUERY_LIMIT = 16

export function* loadChatsByStatusSaga(action: ReturnType<typeof loadChatsByStatusAction.request>) {
  const { status, ownerId, operatorId, campaignIds, departmentId } = action.payload
  try {
    const chats = yield call(fetchChats, status, ownerId, operatorId, campaignIds, departmentId)
    const hasMoreChats = yield checkMoreChatsAvaliability(status, ownerId, campaignIds, operatorId, departmentId)
    yield put(setHasMoreChatsAction({ state: hasMoreChats, status }))
    yield put(loadChatsByStatusAction.success({ chats, status }))
  } catch (error) {
    yield put(loadChatsByStatusAction.failure(error))
  }
}

function* fetchChats(
  status: string[],
  ownerId: string,
  operatorId: string,
  campaignIds: string[],
  departmentId: string
) {
  const chatsQuery = createChatsQuery(status, ownerId, CHATS_LIMIT, campaignIds, operatorId, departmentId)
  const chatsQuerySnapshot = yield call(getDocs, chatsQuery)
  return chatsQuerySnapshot.docs.map(doc => doc.data() as Chat)
}

function createChatsQuery(
  status: string[],
  ownerId: string,
  queryLimit: number,
  campaignIds: string[],
  operatorId?: string,
  departmentId?: string
) {
  const collectionName = `chats`
  const data = [
    collection(_fireStore, collectionName),
    where('ownerId', '==', ownerId),
    where('status', 'in', status),
    orderBy('updatedAt', 'desc'),
    limit(queryLimit)
  ]
  if (operatorId) {
    data.push(where('operatorsId', 'array-contains', operatorId))
  }
  if (campaignIds?.length) {
    data.push(where('campaignId', 'in', campaignIds))
  }
  if (departmentId) {
    data.push(where('departmentId', '==', departmentId))
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return query(...data)
}

function* checkMoreChatsAvaliability(
  status: string[],
  ownerId: string,
  campaignIds: string[],
  operatorId?: string,
  departmentId?: string
) {
  const countQuery = createChatsQuery(status, ownerId, COUNT_QUERY_LIMIT, campaignIds, operatorId, departmentId)
  const count = yield countDocumentsInQuery(countQuery)
  return count >= COUNT_QUERY_LIMIT
}

async function countDocumentsInQuery(query: Query<DocumentData>) {
  try {
    const snapshot = await getDocs(query)
    return snapshot.size
  } catch (error) {
    throw new Error(`Error on count docs:`, error)
  }
}
