import { ActionType, createAction } from 'typesafe-actions'
import { ChatState } from '../../types'
import { DTO } from './types'

export const setHasMoreChatsAction = createAction('@message/SET_HAS_MORE-CHATS')<DTO>()

const success = (state: ChatState, { payload }: ActionType<typeof setHasMoreChatsAction>): ChatState => {
  const [firstStatus] = payload.status
  switch (firstStatus) {
    case 'open':
      return {
        ...state,
        hasMoreOpenChats: payload.state
      }
    case 'in_progress':
      return {
        ...state,
        hasMoreInProgressChats: payload.state
      }
    default:
      return {
        ...state,
        hasMoreFinishedChats: payload.state
      }
  }
}

export const setHasMoreChatsHandle = { asyncAction: setHasMoreChatsAction, success }
