import { _fireStore } from '@/helpers/firebase'
import { Campaign } from '@/models/campaign.model'
import { DepartmentModel } from '@/models/department.model'
import { User } from '@/models/user.model'
import { collection, getDocs, query, where } from 'firebase/firestore'

export async function buildDataAccordingUserRole(user: User, companyId: string) {
  const role = user.companies?.find(company => company.id === companyId)?.role[0] || null
  const userAttendCampaigns: User['attendCampaigns'] = []
  const userDepartments: User['departments'] = []
  if (role === 'MASTER') {
    const campaigns: Campaign[] = await findManyCampaignsCompanyId(companyId, user.ownerId)
    if (!campaigns.length) return
    const attendCampaigns: User['attendCampaigns'] = campaigns.map(campaign => ({
      id: campaign.id,
      companyId: campaign.companyId,
      nomeCampanha: campaign.nomeCampanha
    }))
    userAttendCampaigns.push(...attendCampaigns)

    const departments: DepartmentModel[] = await findManyDepartmentsByCompanyId(companyId, user.ownerId)
    if (!departments.length) return
    const attendDepartments: User['departments'] = departments.map(department => ({
      id: department.id,
      companyId: department.companyId,
      name: department.name
    }))
    userDepartments.push(...attendDepartments)
  } else {
    userAttendCampaigns.push(...user.attendCampaigns)
    userDepartments.push(...user.departments)
  }

  return { userAttendCampaigns, userDepartments }
}

async function findManyCampaignsCompanyId(companyId: string, ownerId: string) {
  const _query = query(
    collection(_fireStore, 'campaigns'),
    where('companyId', '==', companyId),
    where('ownerId', '==', ownerId)
  )
  const querySnapshot = await getDocs(_query)
  if (querySnapshot.empty) return []
  const campaigns = querySnapshot.docs.map(doc => doc.data() as Campaign)
  return campaigns
}

async function findManyDepartmentsByCompanyId(companyId: string, ownerId: string) {
  const _query = query(
    collection(_fireStore, 'departments'),
    where('companyId', '==', companyId),
    where('ownerId', '==', ownerId)
  )
  const departmentDocs = await getDocs(_query)
  if (departmentDocs.empty) return []
  return departmentDocs.docs.map(doc => doc.data() as DepartmentModel)
}
