import { ActionType, createAsyncAction } from 'typesafe-actions'
import { Request, Success, Failure } from './types'
import { StickerState } from '../../types'

export const deleteStickerAction = createAsyncAction(
  '@sticker/DELETE_STICKER_REQUEST',
  '@sticker/DELETE_STICKER_SUCCESS',
  '@sticker/DELETE_STICKER_FAILURE'
)<Request, Success, Failure>()

const request = (state: StickerState): StickerState => ({
  ...state,
  isLoadingDeleteSticker: true
})

const success = (state: StickerState, action: ActionType<typeof deleteStickerAction.success>): StickerState => ({
  ...state,
  stickers: state.stickers.filter(sticker => sticker.name !== action.payload.name),
  isLoadingDeleteSticker: false,
  isSuccessOnLoadingDeleteSticker: true
})

const failure = (state: StickerState): StickerState => ({
  ...state,
  isLoadingDeleteSticker: false,
  isErrorOnDeleteSticker: true
})

export const deleteStickerHandle = { asyncAction: deleteStickerAction, request, success, failure }
