import { ActionType, createAction } from 'typesafe-actions'
import { ChatState } from '../../types'
import { addChatInChatsState } from '../helpers'
import { DTO } from './types'

export const addChatAction = createAction('@chat/ADD_CHAT')<DTO>()

const success = (state: ChatState, action: ActionType<typeof addChatAction>): ChatState => {
  switch (action.payload.chat.status) {
    case 'open':
      return {
        ...state,
        openChats: addChatInChatsState(state.openChats, action.payload.chat)
      }
    case 'in_progress':
      return {
        ...state,
        inProgressChats: addChatInChatsState(state.inProgressChats, action.payload.chat)
      }
    default:
      return {
        ...state,
        finishedChats: addChatInChatsState(state.finishedChats, action.payload.chat)
      }
  }
}

export const addChatHandle = { asyncAction: addChatAction, success }
