import React, { ReactNode, useEffect } from 'react'
import withRouter from '../../components/withRouter'

type IndexProps = {
  children: ReactNode
  router: any
}

function Index(props: IndexProps) {
  const capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }

  useEffect(() => {
    const currentage = capitalizeFirstLetter(props?.router.location.pathname)
    document.title = currentage
  }, [])

  return (
    <>
      <div className="layout-wrapper d-lg-flex" style={{ justifyContent: 'center' }}>
        {props.children}
      </div>
    </>
  )
}

export default withRouter(Index)
