import { _fireBaseStorage } from '@/helpers/firebase'
import { getDownloadURL, ref, listAll } from 'firebase/storage'
import { getStickersAction } from '../../handles'
import { put } from 'redux-saga/effects'
import { Sticker } from '../../types'

export function* getStickersSaga(action: ReturnType<typeof getStickersAction.request>) {
  try {
    const { folderPath } = action.payload
    const stickers: Sticker[] = yield getAllFilesFromStorage(folderPath)
    yield put(getStickersAction.success(stickers))
  } catch (error) {
    yield put(getStickersAction.failure(null))
  }
}

export async function getAllFilesFromStorage(folderPath: string) {
  try {
    const folderRef = ref(_fireBaseStorage, folderPath)
    const response = await listAll(folderRef)
    const stickers: Sticker[] = []

    for (let i = 0; i < response.items.length; i++) {
      const itemRef = response.items[i]

      const url = await getDownloadURL(itemRef)
      stickers.push({ name: itemRef.name, url })
    }

    return stickers
  } catch (error) {
    console.log(error)
    throw error
  }
}
