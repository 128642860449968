import { _fireStore } from '@/helpers/firebase'
import { collection, doc, getDocs, updateDoc, writeBatch } from 'firebase/firestore'
import { call, put } from 'redux-saga/effects'
import { readMessagesFromChatAction } from '../../handles'
import { buildUnreadMessagesQuery } from './helpers'

export function* readMessagesFromChatSaga(action: ReturnType<typeof readMessagesFromChatAction.request>) {
  const { chat, ownerId } = action.payload
  try {
    yield put(readMessagesFromChatAction.success({ ...chat, unreadMessagesCount: 0 }))
    yield call(clearUnreadMessagesCoutFromChat, chat.id, chat.updatedAt)
    yield call(readMessagesFromChat, chat.id, ownerId)
  } catch (error) {
    console.log({ readMessagesFromChatSaga: error })
    console.log({ chat })
    yield put(readMessagesFromChatAction.failure(chat))
  }
}

async function clearUnreadMessagesCoutFromChat(chatId: string, updatedAt: number) {
  const collectionName = `chats`
  const chatRef = doc(collection(_fireStore, collectionName), chatId)
  await updateDoc(chatRef, { unreadMessagesCount: 0 })
}

async function readMessagesFromChat(chatId: string, ownerId: string) {
  try {
    const messagesQuerySnapShot = await getDocs(buildUnreadMessagesQuery(chatId, ownerId))
    if (messagesQuerySnapShot.empty) return
    const batch = writeBatch(_fireStore)
    messagesQuerySnapShot.docs.map(async doc => {
      batch.update(doc.ref, { isRead: true })
    })
    await batch.commit()
  } catch (error) {
    throw new Error(error)
  }
}
