import React, { useEffect } from 'react'
import './assets/scss/themes.scss'
import { initFirebaseBackend } from './helpers/firebase'
import { useUser } from './redux/user'
import { saveAuthUser } from './redux/user/actions'
import Routes from './routes'

initFirebaseBackend()

function App() {
  const { userDispatch } = useUser()
  useEffect(() => {
    const userAuthenticated = localStorage.getItem('devchat_app_userAuthenticated')
    if (userAuthenticated) {
      const parsedAuthUser = JSON.parse(userAuthenticated)
      userDispatch(
        saveAuthUser({
          email: parsedAuthUser.email,
          name: parsedAuthUser.name,
          companies: parsedAuthUser.companies,
          attendCampaigns: parsedAuthUser.attendCampaigns,
          id: parsedAuthUser.id,
          ownerId: parsedAuthUser.ownerId,
          isAdmin: parsedAuthUser.isAdmin,
          departments: parsedAuthUser.departments,
          isWork: parsedAuthUser.isWork,
          role: parsedAuthUser.role
        })
      )
    }
  }, [])

  return <Routes />
}

export default App
