import { ActionType, createAsyncAction } from 'typesafe-actions'
import { Request, Success, Failure } from './types'
import { ChatState } from '../../types'
import { updateChatInChatsState } from '../helpers'

export const readMessagesFromChatAction = createAsyncAction(
  '@chat/READ_MESSAGES_FROM_CHAT_REQUEST',
  '@chat/READ_MESSAGES_FROM_CHAT_SUCCESS',
  '@chat/READ_MESSAGES_FROM_CHAT_FAILURE'
)<Request, Success, Failure>()

const request = (state: ChatState, action: ActionType<typeof readMessagesFromChatAction.request>): ChatState => ({
  ...state,
  isLoadingReadMessagesFromChat: true,
  isErrorOnReadMessagesFromChat: false
})

const success = (state: ChatState, action: ActionType<typeof readMessagesFromChatAction.success>): ChatState => {
  switch (action.payload.status) {
    case 'open':
      return {
        ...state,
        isLoadingReadMessagesFromChat: false,
        isErrorOnReadMessagesFromChat: false,
        openChats: updateChatInChatsState(action.payload, state.openChats)
      }
    case 'in_progress':
      return {
        ...state,
        isLoadingReadMessagesFromChat: false,
        isErrorOnReadMessagesFromChat: false,
        inProgressChats: updateChatInChatsState(action.payload, state.inProgressChats)
      }
    case 'close':
      return {
        ...state,
        isLoadingReadMessagesFromChat: false,
        isErrorOnReadMessagesFromChat: false,
        finishedChats: updateChatInChatsState(action.payload, state.finishedChats)
      }
    default:
      break
  }
}

const failure = (state: ChatState, action: ActionType<typeof readMessagesFromChatAction.failure>): ChatState => ({
  ...state,
  isLoadingReadMessagesFromChat: false,
  isErrorOnReadMessagesFromChat: true,
  chats: updateChatInChatsState(action.payload, state.chats)
})

export const readMessagesFromChatHandle = { asyncAction: readMessagesFromChatAction, request, success, failure }
