import { ActionType, createReducer } from 'typesafe-actions'
import { createMessageAsync, setHasMoreMessages } from './actions'
import { MessageAction, MessageState } from './types'

const initialState: MessageState = {
  messages: [],
  isLoading: false,
  error: false,
  hasMoreMessages: false,
  isErrorOnCreateMessage: false,
  isLoadingCreateMessage: false,
  isSuccessCreateMessage: false,
  isLoadingAttachment: false,
  isLoadingAudio: false,
  isLoadingSticker: false
}

export const messageReducer = createReducer<MessageState, MessageAction>(initialState)
  .handleAction(
    createMessageAsync.request,
    (state: MessageState, action: ActionType<typeof createMessageAsync.request>): MessageState => ({
      ...state,
      isLoading: true,
      isLoadingCreateMessage: true,
      isLoadingAttachment: action.payload?.typeFile === 'attachment',
      isLoadingAudio: action.payload?.typeFile === 'audio',
      isLoadingSticker: action.payload?.typeFile === 'sticker'
    })
  )
  .handleAction(
    createMessageAsync.success,
    (state: MessageState): MessageState => ({
      ...state,
      isLoading: false,
      isSuccessCreateMessage: true,
      isLoadingAttachment: false,
      isLoadingAudio: false,
      isLoadingSticker: false
    })
  )
  .handleAction(
    createMessageAsync.failure,
    (state: MessageState): MessageState => ({
      ...state,
      isLoading: false,
      error: true,
      isErrorOnCreateMessage: true,
      isLoadingCreateMessage: false,
      isLoadingAttachment: false,
      isLoadingAudio: false,
      isLoadingSticker: false
    })
  )
  .handleAction(
    setHasMoreMessages,
    (state: MessageState, action: ActionType<typeof setHasMoreMessages>): MessageState => ({
      ...state,
      isLoading: false,
      error: null,
      hasMoreMessages: action.payload.state
    })
  )
