import { Dispatch } from 'redux'
import { CompanyAction, CompanyState } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../store'

type UseCompany = {
  companyState: CompanyState
  companyDispatch?: Dispatch<CompanyAction>
}

export const useCompany = (): UseCompany => {
  const companyState = useSelector<ApplicationState, CompanyState>(rootReducer => rootReducer.companyReducer)
  const companyDispatch = useDispatch<Dispatch<CompanyAction>>()
  return { companyState, companyDispatch }
}
