import { _fireStore } from '@/helpers/firebase'
import { isEmpty } from '@/helpers/string.helpers'
import { WebhooksConfigModel } from '@/models/webhooksConfig.model'
import { api } from '@/services/api'
import { collection, doc, getDoc, getDocs, limit, query, where } from 'firebase/firestore'
import { OnStartServiceHookDTO, StartServiceDTO } from './types'

export async function onStartServiceHook({ currentChat }: OnStartServiceHookDTO) {
  try {
    const campaign = await findCampaignById(currentChat?.campaignId)
    const config = await findWebhookConfigByCompanyId(campaign?.companyId)
    if (isEmpty(config)) return
    if (isEmpty(config?.onServiceStart)) return
    const url = config.onServiceStart
    const dto: StartServiceDTO = {
      chat: {
        id: currentChat.id,
        chatName: currentChat.chatName,
        ticket: currentChat.ticket,
        lastMessage: currentChat.lastMessage,
        unreadMessagesCount: currentChat.unreadMessagesCount,
        operators: currentChat.operators,
        requesterNumber: currentChat.requesterNumber,
        receiverNumber: currentChat.receiverNumber,
        startDate: currentChat.startDate,
        endDate: currentChat.endDate,
        duration: currentChat.duration,
        status: currentChat.status,
        createdAt: currentChat.createdAt,
        updatedAt: currentChat.updatedAt,
        type: 'StartServiceCallback'
      }
    }
    await api.post(url, dto)
  } catch (error) {
    console.log('onStartServiceHook error', error)
  }
}

async function findCampaignById(campaignId: string) {
  const collectionName = 'campaigns'
  const collecttionRef = collection(_fireStore, collectionName)
  const docRef = doc(collecttionRef, campaignId)
  const docSnap = await getDoc(docRef)
  if (!docSnap.exists()) return {} as WebhooksConfigModel
  return docSnap.data() as WebhooksConfigModel
}

async function findWebhookConfigByCompanyId(companyId: string) {
  const collectionName = 'webhook-configs'
  const collecttionRef = collection(_fireStore, collectionName)
  const _query = query(collecttionRef, where('companyId', '==', companyId), limit(1))
  const docSnap = await getDocs(_query)
  if (docSnap.empty) return {} as WebhooksConfigModel
  return docSnap.docs.map(doc => doc.data())[0] as WebhooksConfigModel
}
