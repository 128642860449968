import { _fireStore } from '@/helpers/firebase'
import { DocumentData, Query, collection, getDocs, limit, orderBy, query, startAfter, where } from 'firebase/firestore'
import { put } from 'redux-saga/effects'
import { fetchMoreChatsAction, setHasMoreChatsAction } from '../../actions'
import { Chat } from '../../types'

const CHATS_LIMIT = 15
const COUNT_QUERY_LIMIT = 16

export function* fetchMoreChatsSaga(action: ReturnType<typeof fetchMoreChatsAction.request>) {
  yield put(setHasMoreChatsAction({ state: false, status: action.payload.status }))

  try {
    const { lastChat, ownerId, status, operatorId, campaignIds, departmentId } = action.payload
    const { moreChats, count } = yield fetchMoreChats({
      lastChat,
      ownerId,
      status,
      operatorId,
      campaignIds,
      departmentId
    })
    const hasMoreChats = !(count < COUNT_QUERY_LIMIT)
    if (hasMoreChats) {
      yield put(setHasMoreChatsAction({ state: true, status }))
    }
    const hasLastChatIntoCurrent = yield hasLastChatIntoCurrentChats(lastChat, moreChats)
    yield put(
      fetchMoreChatsAction.success(hasLastChatIntoCurrent ? { chats: [], status } : { chats: moreChats, status })
    )
  } catch (error) {
    yield put(fetchMoreChatsAction.failure(error))
  }
}

type FetchMoreChatsDTO = {
  lastChat: Chat
  ownerId: string
  status: string[]
  operatorId?: string
  campaignIds?: string[]
  departmentId?: string
}
async function fetchMoreChats({ lastChat, ownerId, status, campaignIds, departmentId, operatorId }: FetchMoreChatsDTO) {
  try {
    const collectionName = `chats`
    const qData = [
      collection(_fireStore, collectionName),
      where('ownerId', '==', ownerId),
      where('status', 'in', status),
      orderBy('updatedAt', 'desc'),
      startAfter(lastChat.updatedAt),
      limit(CHATS_LIMIT)
    ]
    if (operatorId) {
      qData.push(where('operatorsId', 'array-contains', operatorId))
    }
    if (campaignIds?.length) {
      qData.push(where('campaignId', 'in', campaignIds))
    }
    if (departmentId) {
      qData.push(where('departmentId', '==', departmentId))
    }

    // TODO: improve this
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const q = query(...qData)
    const queryToCountData = [
      collection(_fireStore, collectionName),
      where('ownerId', '==', ownerId),
      where('status', 'in', status),
      orderBy('updatedAt', 'desc'),
      startAfter(lastChat.updatedAt),
      limit(COUNT_QUERY_LIMIT)
    ]
    if (operatorId) {
      queryToCountData.push(where('operatorsId', 'array-contains', operatorId))
    }
    if (campaignIds?.length) {
      queryToCountData.push(where('campaignId', 'in', campaignIds))
    }
    if (departmentId) {
      queryToCountData.push(where('departmentId', '==', departmentId))
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const queryToCount = query(...queryToCountData)
    const count = await countDocumentsInQuery(queryToCount)
    const snapshot = await getDocs(q)
    const moreChats = snapshot.docs.map(doc => doc.data())
    return { moreChats, count }
  } catch (error) {
    throw new Error(`Error fetching more chats: ${error}`)
  }
}

async function countDocumentsInQuery(query: Query<DocumentData>) {
  try {
    const snapshot = await getDocs(query)
    return snapshot.size
  } catch (error) {
    throw new Error(`Error on count docs ${error}`)
  }
}

async function hasLastChatIntoCurrentChats(lastChat: Chat, chats: Chat[]) {
  return chats.some(chat => chat.id === lastChat.id)
}
