import { ChatsCountModel } from '@/models/chat.model'
import { api } from '@/services/api'
import { FetchChatsCountDTO } from './types'

export async function fetchChatsCount(dto: FetchChatsCountDTO): Promise<ChatsCountModel | null> {
  try {
    const response = await api.post<{ chatsCount: ChatsCountModel }>('/chat/fetch-chats-count', dto)
    return response.data.chatsCount
  } catch (error) {
    console.log('fetchChatsCount error', error)
  }
}
