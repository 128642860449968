import { createAsyncAction } from 'typesafe-actions'
import { ContactState } from '../../types'
import { Failure, Request, Success } from './types'
import { toast } from '@/helpers/toast/toast.helper'
import { errorMessages } from '@/helpers/form.helpers'

export const createContactAction = createAsyncAction(
  '@contact/CREATE_CONTACT_REQUEST',
  '@contact/CREATE_CONTACT_SUCCESS',
  '@contact/CREATE_CONTACT_FAILURE'
)<Request, Success, Failure>()

const request = (state: ContactState): ContactState => ({
  ...state,
  isLoadingCreateContact: true
})

const success = (state: ContactState): ContactState => {
  toast({ type: 'success', message: 'Contato salvo com sucesso.' })
  return {
    ...state,
    isSuccessCreateContact: true,
    isLoadingCreateContact: false,
    isErrorOnCreateContact: false
  }
}

const failure = (state: ContactState, action: ReturnType<typeof createContactAction.failure>): ContactState => {
  toast({ type: 'error', message: errorMessages[action.payload.message] || errorMessages['invalid-number'] })
  return {
    ...state,
    isLoadingCreateContact: false,
    isErrorOnCreateContact: true
  }
}

export const createContactHandle = { asyncAction: createContactAction, request, success, failure }
