import { ActionType, createReducer } from 'typesafe-actions'
import { saveAuthUser, signInAsync, signOutAsync } from './actions'
import { updateUserHandle } from './handles'
import { UserActions, UserState } from './types'

const initialState: UserState = {
  userAuthenticated: null,
  errorOnSaveAuthUser: null,
  isLoadingSaveAuthUser: false,
  isLoadingSignIn: false,
  isErrorOnSignIn: null,
  isLoadingSignOut: false,
  isErrorOnSignOut: false,
  isLoadingUpdateUser: false,
  isSuccessUpdateUser: false,
  isErrorOnUpdateUser: false
}

export const userReducer = createReducer<UserState, UserActions>(initialState)
  .handleAction(
    saveAuthUser,
    (state: UserState, action: ActionType<typeof saveAuthUser>): UserState => ({
      ...state,
      isLoadingSaveAuthUser: false,
      userAuthenticated: action.payload
    })
  )
  .handleAction(
    signInAsync.request,
    (state: UserState): UserState => ({
      ...state,
      isLoadingSignIn: true
    })
  )
  .handleAction(
    signInAsync.success,
    (state: UserState, action: ActionType<typeof signInAsync.success>): UserState => ({
      ...state,
      isLoadingSignIn: false,
      userAuthenticated: action.payload.userAuthenticated
    })
  )
  .handleAction(
    signInAsync.failure,
    (state: UserState, action: ActionType<typeof signInAsync.failure>): UserState => ({
      ...state,
      isLoadingSignIn: false,
      isErrorOnSignIn: action.payload
    })
  )
  .handleAction(
    signOutAsync.request,
    (state: UserState): UserState => ({
      ...state,
      isLoadingSignOut: true,
      isErrorOnSignOut: false
    })
  )
  .handleAction(
    signOutAsync.success,
    (state: UserState): UserState => ({
      ...state,
      isLoadingSignOut: false,
      isErrorOnSignOut: false,
      userAuthenticated: null
    })
  )
  .handleAction(
    signOutAsync.failure,
    (state: UserState): UserState => ({
      ...state,
      isLoadingSignOut: false,
      isErrorOnSignOut: true
    })
  )
  .handleAction(updateUserHandle.asyncAction.request, updateUserHandle.request)
  .handleAction(updateUserHandle.asyncAction.success, updateUserHandle.success)
  .handleAction(updateUserHandle.asyncAction.failure, updateUserHandle.failure)
