import { ActionType, createAction, createAsyncAction } from 'typesafe-actions'
import { Failure, Request, Success } from './types'
import { ChatState } from '../../types'

export const watchChatsCountAction = createAsyncAction(
  '@chat/WATCH_CHATS_COUNT_REQUEST',
  '@chat/WATCH_CHATS_COUNT_SUCCESS',
  '@chat/WATCH_CHATS_COUNT_FAILURE'
)<Request, Success, Failure>()

export const stopWatchChatsCountAction = createAction('@chat/STOP_WATCH_CHATS_COUNT')<void>()

const request = (state: ChatState): ChatState => state

const success = (state: ChatState, action: ActionType<typeof watchChatsCountAction.success>): ChatState => ({
  ...state,
  chatsCount: action.payload
})

const failure = (state: ChatState, action: ActionType<typeof watchChatsCountAction.failure>): ChatState => state

export const watchChatsCountHandle = { asyncAction: watchChatsCountAction, request, success, failure }
