import { ActionType, createAsyncAction } from 'typesafe-actions'
import { Failure, Request, Success } from './types'
import { ChatState } from '../../types'
import { updateChatInChatsState } from '../helpers'

export const archiveChatAction = createAsyncAction(
  '@chat/ARCHIVE_CHAT_REQUEST',
  '@chat/ARCHIVE_CHAT_SUCCESS',
  '@chat/ARCHIVE_CHAT_FAILURE'
)<Request, Success, Failure>()

const request = (state: ChatState, action: ActionType<typeof archiveChatAction.request>): ChatState => ({
  ...state,
  isLoadingArchiveChat: true,
  isErrorOnArchiveChat: false
})

const success = (state: ChatState, action: ActionType<typeof archiveChatAction.success>): ChatState => ({
  ...state,
  isLoadingArchiveChat: false,
  isErrorOnArchiveChat: false,
  chats: updateChatInChatsState(action.payload, state.chats)
})

const failure = (state: ChatState, action: ActionType<typeof archiveChatAction.failure>): ChatState => ({
  ...state,
  isLoadingArchiveChat: false,
  isErrorOnArchiveChat: true,
  chats: updateChatInChatsState(action.payload, state.chats)
})

export const archiveChatHandle = { asyncAction: archiveChatAction, request, success, failure }
