import { put } from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { _fireBaseStorage } from '@/helpers/firebase'
import { saveNewStickerAction } from '../../actions'
import { Sticker } from '../../types'

export function* saveNewStickerSaga(action: ReturnType<typeof saveNewStickerAction.request>) {
  try {
    const { file, ownerId } = action.payload
    const sticker: Sticker = yield saveNewSticker(file, ownerId)
    yield put(saveNewStickerAction.success(sticker))
  } catch (error) {
    yield put(saveNewStickerAction.failure({ isError: false }))
  }
}

async function saveNewSticker(file: any, ownerId: string) {
  try {
    const { fileName, fileUrl } = await uploadFileHandle(file, ownerId)
    return {
      url: fileUrl,
      name: fileName
    }
  } catch (error: any) {
    console.error('saveNewSticker error', error)
    throw error
  }
}

async function uploadFileHandle(file: any, ownerId: string) {
  const currentFileName = `${file.name}.webp`
  const filePath = `storage_to_${ownerId}/data/media/sticker/`

  if (file.url) {
    const imageFileBlob = await fetchFileFromUrl(file.url)
    const imageFile = new File([imageFileBlob], currentFileName, { type: 'image/webp' })
    const { fileName, fileUrl } = await uploadFileToStorage(imageFile, filePath)
    return { fileName, fileUrl }
  } else {
    const { fileName, fileUrl } = await uploadFileToStorage(file, filePath)
    return { fileName, fileUrl }
  }
}

async function uploadFileToStorage(file: File, filePath: string) {
  const fileExt = 'webp'
  const buildedFileName = `${uuid()}.${fileExt}`
  const fileName = filePath + buildedFileName
  const storageRef = ref(_fireBaseStorage, fileName)
  try {
    const snapshot = await uploadBytes(storageRef, file)
    const downloadURL = await getDownloadURL(snapshot.ref)
    return { fileName: buildedFileName, fileUrl: downloadURL }
  } catch (error) {
    throw new Error(error)
  }
}

async function fetchFileFromUrl(url) {
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error(`Não foi possível baixar o arquivo: ${response.status}`)
  }
  const blob = await response.blob()
  return blob
}
