import { createReducer } from 'typesafe-actions'
import { saveNewStickerHandle, getStickersHandle, deleteStickerHandle } from './handles'
import { StickerAction, StickerState } from './types'

const initialState: StickerState = {
  stickers: [],
  isErrorOnSaveNewSticker: false,
  isLoadingSaveNewSticker: false,
  isSuccessOnLoadingSaveNewSticker: false,
  isErrorOnGetStickers: false,
  isLoadingGetStickers: false,
  isSuccessOnLoadingGetStickers: false,
  isLoadingDeleteSticker: false,
  isSuccessOnLoadingDeleteSticker: false,
  isErrorOnDeleteSticker: false
}

export const stickerReducer = createReducer<StickerState, StickerAction>(initialState)
  .handleAction(saveNewStickerHandle.asyncAction.request, saveNewStickerHandle.request)
  .handleAction(saveNewStickerHandle.asyncAction.success, saveNewStickerHandle.success)
  .handleAction(saveNewStickerHandle.asyncAction.failure, saveNewStickerHandle.failure)
  .handleAction(getStickersHandle.asyncAction.request, getStickersHandle.request)
  .handleAction(getStickersHandle.asyncAction.success, getStickersHandle.success)
  .handleAction(getStickersHandle.asyncAction.failure, getStickersHandle.failure)
  .handleAction(deleteStickerHandle.asyncAction.request, deleteStickerHandle.request)
  .handleAction(deleteStickerHandle.asyncAction.success, deleteStickerHandle.success)
  .handleAction(deleteStickerHandle.asyncAction.failure, deleteStickerHandle.failure)
