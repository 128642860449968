import { ActionType, createAction } from 'typesafe-actions'
import { ChatState } from '../../types'

export const clearActiveChatAction = createAction('@chat/CLEAR_ACTIVE_CHAT')<void>()

const success = (state: ChatState, action: ActionType<typeof clearActiveChatAction>): ChatState => ({
  ...state,
  activeChat: null
})

export const clearActiveChatHandle = { asyncAction: clearActiveChatAction, success }
