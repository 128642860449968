import { _fireStore } from '@/helpers/firebase'
import { collection, doc, updateDoc } from 'firebase/firestore'
import { put } from 'redux-saga/effects'
import { changeChatStatusAction, clearActiveChatAction } from '../../actions'

export function* changeChatStatusSaga(action: ReturnType<typeof changeChatStatusAction.request>) {
  const { chatId, chatStatus } = action.payload

  try {
    yield changeChatStatus(chatStatus, chatId)
    yield put(changeChatStatusAction.success())
    yield put(clearActiveChatAction())
  } catch (error) {
    console.error('Error when ending chat:', error)
    yield put(changeChatStatusAction.failure(error))
  }
}

async function changeChatStatus(chatStatus: any, chatId: string) {
  const collectionName = `chats`
  const chatDocRef = doc(collection(_fireStore, collectionName), chatId)
  await updateDoc(chatDocRef, { status: chatStatus, endDate: chatStatus === 'close' ? new Date().getTime() : null })
}
