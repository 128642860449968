import { createAsyncAction } from 'typesafe-actions'
import { ChatState } from '../../types'
import { Failure, Request, Success } from './types'

export const customButtonCallAction = createAsyncAction(
  '@chat/CUSTOM_BUTTON_CALL_REQUEST',
  '@chat/CUSTOM_BUTTON_CALL_SUCCESS',
  '@chat/CUSTOM_BUTTON_CALL_FAILURE'
)<Request, Success, Failure>()

const request = (state: ChatState): ChatState => ({
  ...state,
  isLoadingCustomButtonCall: true
})

const success = (state: ChatState): ChatState => ({
  ...state,
  isLoadingCustomButtonCall: false,
  isSuccessOnCustomButtonCall: false
})
const failure = (state: ChatState): ChatState => ({
  ...state,
  isLoadingCustomButtonCall: false,
  isErrorOnCustomButtonCall: true
})

export const customButtonCallHandle = { asyncAction: customButtonCallAction, request, success, failure }
