import { _fireStore } from '@/helpers/firebase'
import { isEmpty } from '@/helpers/string.helpers'
import { ContactModel } from '@/models/contact.model'
import { updateActiveChatAction, updateChatFirebaseAction } from '@/redux/chat/actions'
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore'
import { put } from 'redux-saga/effects'
import { createContactAction } from '../../handles'
import { CreateContactDTO } from '../../handles/createContactAction/types'
import { Campaign } from '@/models/campaign.model'
import { zApiMultiService } from '@/services/api'
import { removeSpecialCharacters } from '@/helpers/number.helpers'
import { toast } from '@/helpers/toast/toast.helper'

export function* createContactSaga(action: ReturnType<typeof createContactAction.request>) {
  try {
    const { contactDTO, chatId } = action.payload
    const contactByNumber: ContactModel = yield getContactByNumber(contactDTO.number, contactDTO.companyId)
    if (!isEmpty(contactByNumber)) throw new Error('contactAlreadyExists')
    const validatedContactNumber = yield validateContactNumber(contactDTO.number, contactDTO.connectedCampaign)
    if (!validatedContactNumber) throw new Error('invalid-number')
    yield createContact(contactDTO)
    yield put(createContactAction.success())
    if (!chatId) return
    yield put(updateActiveChatAction({ chatName: contactDTO.name }))
    yield put(updateChatFirebaseAction.request({ chatId, updateChat: { chatName: contactDTO.name } }))
  } catch (error) {
    if (error.message === 'disconnected_campaign') {
      toast({ type: 'error', message: 'Campanha desconectada, por favor conecte e tente novamente.' })
    }
    yield put(createContactAction.failure(error))
  }
}

async function createContact(newContact: CreateContactDTO) {
  const collectionName = `contacts`
  const contactDoc = doc(collection(_fireStore, collectionName))
  newContact.id = contactDoc.id
  await setDoc(contactDoc, newContact)
}

async function getContactByNumber(number: string, companyId: string): Promise<ContactModel> {
  const _collectionName = `contacts`
  const _query = query(
    collection(_fireStore, _collectionName),
    where('companyId', '==', companyId),
    where('number', '==', number)
  )
  const contactDocs = await getDocs(_query)
  if (contactDocs.empty) return null
  return contactDocs.docs.map(doc => doc.data())[0] as ContactModel
}

async function validateContactNumber(number: string, connectedCampaign: Campaign) {
  try {
    if (!connectedCampaign) throw new Error('disconnected_campaign')

    const { id, token } = connectedCampaign
    const payload = {
      phones: [removeSpecialCharacters(number)]
    }
    const response = await zApiMultiService.post(
      `/instances/campanhaId/${id}/token/${token}/phone-exists-batch`,
      payload
    )

    if (response.data[0].exists === true) {
      const validatedNumber = response.data[0].outputPhone
      return validatedNumber
    } else {
      return null
    }
  } catch (error) {
    console.log('validateContactNumber', error)
    throw new Error('invalid-number')
  }
}
