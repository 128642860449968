import { _fireStore } from '@/helpers/firebase'
import { collection, doc, updateDoc } from 'firebase/firestore'
import { put } from 'redux-saga/effects'
import { clearActiveChatAction } from '../../actions'
import { finalCloseChatAction } from '../../handles/finalCloseChatAction'
import { Chat } from '../../types'

export function* finalCloseChatSaga(action: ReturnType<typeof finalCloseChatAction.request>) {
  const { chat } = action.payload
  try {
    yield updateChat(chat.id, { ...chat })
    yield put(finalCloseChatAction.success())
    yield put(clearActiveChatAction())
  } catch (error) {
    console.error('Error when ending chat:', error)
    yield put(finalCloseChatAction.failure(error))
  }
}

async function updateChat(chatId: string, updatedData: Partial<Chat>) {
  const collectionName = `chats`
  const chatDocRef = doc(collection(_fireStore, collectionName), chatId)
  await updateDoc(chatDocRef, updatedData)
}
