import { ActionType, createAsyncAction } from 'typesafe-actions'
import { DepartmentState } from '../../types'
import { Failure, Request, Success } from './types'

export const selectDepartmentAction = createAsyncAction(
  '@department/SELECT_DEPARTMENT_REQUEST',
  '@department/SELECT_DEPARTMENT_SUCCESS',
  '@department/SELECT_DEPARTMENT_FAILURE'
)<Request, Success, Failure>()

const request = (state: DepartmentState): DepartmentState => ({
  ...state,
  isLoadingSelectDepartment: true
})

const success = (
  state: DepartmentState,
  action: ActionType<typeof selectDepartmentAction.success>
): DepartmentState => {
  return {
    ...state,
    departmentCheck: action.payload?.departmentCheck || null,
    selectedDepartment: action.payload?.selectedDepartment || null,
    isSuccessSelectDepartment: true,
    isLoadingSelectDepartment: false
  }
}

const failure = (
  state: DepartmentState,
  { payload }: ReturnType<typeof selectDepartmentAction.failure>
): DepartmentState => {
  return {
    ...state,
    isLoadingSelectDepartment: false,
    departmentCheck: payload?.departmentCheck || null,
    selectedDepartment: payload?.selectedDepartment || state.selectedDepartment,
    isErrorSelectDepartment: payload.isError
  }
}

export const selectDeparmentHandle = {
  asyncAction: selectDepartmentAction,
  request,
  success,
  failure
}
