import { createAsyncAction } from 'typesafe-actions'
import { ChatState } from '../../types'
import { Failure, Request, Success } from './types'

export const updateChatFirebaseAction = createAsyncAction(
  '@chat/UPDATE_CHAT_FIREBASE_NEW_CHAT_REQUEST',
  '@chat/UPDATE_CHAT_FIREBASE_NEW_CHAT_SUCCESS',
  '@chat/UPDATE_CHAT_FIREBASE_NEW_CHAT_FAILURE'
)<Request, Success, Failure>()

const request = (state: ChatState): ChatState => ({
  ...state,
  isLoadingUpdateChatFirebase: true,
  isErrorOnUpdateChatFirebase: false
})

const success = (state: ChatState): ChatState => ({
  ...state,
  isLoadingUpdateChatFirebase: false,
  isErrorOnUpdateChatFirebase: false
})
const failure = (state: ChatState): ChatState => ({
  ...state,
  isLoadingUpdateChatFirebase: false,
  isErrorOnUpdateChatFirebase: true
})

export const updateChatFirebaseHandle = { asyncAction: updateChatFirebaseAction, request, success, failure }
