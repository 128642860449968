import { _fireStore } from '@/helpers/firebase'
import { collection, doc, updateDoc } from 'firebase/firestore'
import { call, put } from 'redux-saga/effects'
import { Chat } from '../../types'

import { updateChatFirebaseAction } from '../../handles/updateChatFirebaseAction'

export function* updateChatFirebaseSaga(action: ReturnType<typeof updateChatFirebaseAction.request>) {
  const { chatId, updateChat } = action.payload
  try {
    yield call(updateChatFirebase, chatId, updateChat)
    yield put(updateChatFirebaseAction.success())
  } catch (e) {
    yield put(updateChatFirebaseAction.failure(e))
  }
}

async function updateChatFirebase(chatId: string, updateChat: Partial<Chat>) {
  const _collectionName = `chats`
  const newChatDoc = doc(collection(_fireStore, _collectionName), chatId)
  await updateDoc(newChatDoc, { ...updateChat })
}
