import { ActionType, createAsyncAction } from 'typesafe-actions'
import { Failure, Request, Success } from './types'
import { ChatState } from '../../types'

export const transferChatAction = createAsyncAction(
  '@chat/TRANSFER_CHAT_REQUEST',
  '@chat/TRANSFER_CHAT_SUCCESS',
  '@chat/TRANSFER_CHAT_FAILURE'
)<Request, Success, Failure>()

const request = (state: ChatState): ChatState => ({
  ...state,
  isLoadingTransferChat: true
})

const success = (state: ChatState, { payload }: ActionType<typeof transferChatAction.success>): ChatState => {
  const { transferedChat } = payload

  switch (transferedChat.status) {
    case 'open':
      return {
        ...state,
        openChats: state.openChats.filter(chat => chat.id !== transferedChat.id),
        inProgressChats: state.inProgressChats.filter(chat => chat.id !== transferedChat.id),
        finishedChats: state.finishedChats.filter(chat => chat.id !== transferedChat.id)
      }
    case 'in_progress':
      return {
        ...state,
        openChats: state.openChats.filter(chat => chat.id !== transferedChat.id),
        inProgressChats: state.inProgressChats.filter(chat => chat.id !== transferedChat.id),
        finishedChats: state.finishedChats.filter(chat => chat.id !== transferedChat.id)
      }
    default:
      return {
        ...state,
        openChats: state.openChats.filter(chat => chat.id !== transferedChat.id),
        inProgressChats: state.inProgressChats.filter(chat => chat.id !== transferedChat.id),
        finishedChats: state.finishedChats.filter(chat => chat.id !== transferedChat.id),
        isSuccessOnTransferChat: true,
        isLoadingTransferChat: false
      }
  }
}

const failure = (state: ChatState): ChatState => ({
  ...state,
  isErrorOnTransferChat: true,
  isLoadingTransferChat: false
})

export const transferChatHandle = { asyncAction: transferChatAction, request, success, failure }
