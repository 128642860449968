import { Schedule } from '@/models/schedule'

export function getTodaySchedule(data: Schedule) {
  try {
    const today = new Date()
    const dayOfWeek = today.toLocaleDateString('pt-BR', { weekday: 'long' }).toLowerCase()
    const dayMap: Record<string, string> = {
      'segunda-feira': 'monday',
      'terça-feira': 'tuesday',
      'quarta-feira': 'wednesday',
      'quinta-feira': 'thursday',
      'sexta-feira': 'friday',
      sábado: 'saturday',
      domingo: 'sunday'
    }
    const dayKey = dayMap[dayOfWeek]
    return dayKey ? data[dayKey as keyof Schedule] : null
  } catch (error) {
    console.log('getTodaySchedule', error)
    return null
  }
}

export function getCurrentTimeInMinutes() {
  const today = new Date()
  const currentTime = today.toTimeString().split(' ')[0].substring(0, 5)
  return timeToMinutes(currentTime)
}

export function timeToMinutes(time: string): number {
  const [hours, minutes] = time.split(':').map(Number)
  return hours * 60 + minutes
}

export async function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
