import { _fireStore } from '@/helpers/firebase'
import { MessageModel } from '@/models/message.model'
import { DocumentData, Query, collection, getDocs, limit, orderBy, query, startAfter, where } from 'firebase/firestore'

export async function countDocumentsInQuery(query: Query<DocumentData>) {
  try {
    const snapshot = await getDocs(query)
    return snapshot.size
  } catch (error) {
    throw new Error(`Error on count docs: ${error}`)
  }
}

export function lastMessagesByChatFromDateQuery(chatId: string, ownerId: string, fromDate: number, queryLimit: number) {
  return query(
    collection(_fireStore, `messages`),
    where('ownerId', '==', ownerId),
    where('chatId', '==', chatId),
    orderBy('createdAt', 'desc'),
    startAfter(fromDate),
    limit(queryLimit)
  )
}

export function hasLastMessageIntoCurrentMessages(lastMessage: MessageModel, messages: MessageModel[]) {
  return messages.some(message => message.messageId === lastMessage.messageId)
}
