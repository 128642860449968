import React, { ReactNode, useEffect } from 'react'
import withRouter from '../components/withRouter'

type NonAuthProps = {
  children: ReactNode
  router: any
}

function NonAuth(props: NonAuthProps) {
  const capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }

  useEffect(() => {
    const currentage = capitalizeFirstLetter(props.router.location.pathname)
    document.title = currentage
  }, [])

  return <React.Fragment>{props.children}</React.Fragment>
}

export default withRouter(NonAuth)
