import { ActionType, createAction } from 'typesafe-actions'
import { ChatState } from '../../types'
import { addUniqueMessages, updateChatInChatsState } from '../helpers'
import { DTO } from './types'

export const setActiveChatMessageAction = createAction('@chat/SET_ACTIVE_CHAT_MESSAGE')<DTO>()

const success = (state: ChatState, action: ActionType<typeof setActiveChatMessageAction>): ChatState => {
  const newActiveChat = {
    ...state.activeChat,
    messages: addUniqueMessages(state.activeChat.messages, [action.payload]),
    lastMessage: action.payload
  }
  return {
    ...state,
    activeChat: newActiveChat,
    chats: updateChatInChatsState(newActiveChat, state.chats)
  }
}

export const setActiveChatMessageHandle = { asyncAction: setActiveChatMessageAction, success }
