import { ActionType, createAction } from 'typesafe-actions'
import { DTO } from './types'
import { ChatState } from '../../types'

export const removeArchiveChatAction = createAction('@chat/REMOVE_ARCHIVE_CHAT')<DTO>()

const success = (state: ChatState, action: ActionType<typeof removeArchiveChatAction>): ChatState => ({
  ...state,
  archiveChats: state.archiveChats.filter(chat => chat.id !== action.payload.id)
})

export const removeArchiveChatHandle = { asyncAction: removeArchiveChatAction, success }
