import { createAsyncAction } from 'typesafe-actions'
import { Failure, Request, Success } from './types'
import { UserState } from '../../types'

export const updateUserAction = createAsyncAction(
  '@user/UPDATE_USER_REQUEST',
  '@user/UPDATE_USER_SUCCESS',
  '@user/UPDATE_USER_FAILURE'
)<Request, Success, Failure>()

const request = (state: UserState): UserState => ({
  ...state,
  isLoadingUpdateUser: true
})

const success = (state: UserState): UserState => ({
  ...state,
  isLoadingUpdateUser: false,
  isSuccessUpdateUser: true
})

const failure = (state: UserState): UserState => ({
  ...state,
  isLoadingUpdateUser: false,
  isErrorOnUpdateUser: true
})

export const updateUserHandle = { asyncAction: updateUserAction, request, success, failure }
