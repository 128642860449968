import { put } from 'redux-saga/effects'
import { signOutAsync, updateUserAction } from '../../actions'

export function* signOutSaga(action: ReturnType<typeof signOutAsync.request>) {
  const { id } = action.payload
  try {
    localStorage.removeItem('devchat_app_userAuthenticated')
    yield put(updateUserAction.request({ userId: id, userUpdateData: { isWork: false } }))
    yield put(signOutAsync.success())
  } catch (error) {
    yield put(signOutAsync.failure(error))
  }
}
