import { toast } from '@/helpers/toast/toast.helper'
import axios from 'axios'
import { put } from 'redux-saga/effects'
import { customButtonCallAction } from '../../handles/customButtonCallAction'

export function* customButtonCallSaga(action: ReturnType<typeof customButtonCallAction.request>) {
  try {
    const { currentChat, customButtonUrl } = action.payload
    yield axios.post(customButtonUrl, { ...currentChat, phone: currentChat.requesterNumber })
    yield put(customButtonCallAction.success())
    toast({ type: 'success', message: 'Chamada realizada com sucesso!' })
  } catch (error) {
    toast({ type: 'error', message: 'Erro ao realizar chamada!' })
    yield put(customButtonCallAction.failure())
  }
}
