import { ActionType, createAsyncAction } from 'typesafe-actions'
import { Failure, Request, Success } from './types'
import { ChatState } from '../../types'

export const deleteChatAction = createAsyncAction(
  '@chat/DELETE_CHAT_REQUEST',
  '@chat/DELETE_CHAT_SUCCESS',
  '@chat/DELETE_CHAT_FAILURE'
)<Request, Success, Failure>()

const request = (state: ChatState, action: ActionType<typeof deleteChatAction.request>): ChatState => ({
  ...state,
  isLoadingDeleteChat: true
})

const success = (state: ChatState, action: ActionType<typeof deleteChatAction.success>): ChatState => ({
  ...state,
  isLoadingDeleteChat: false,
  isErrorOnDeleteChat: false,
  chats: state.chats.filter(chat => chat.id !== action.payload.id),
  activeChat: {} as any
})

const failure = (state: ChatState, action: ActionType<typeof deleteChatAction.failure>): ChatState => ({
  ...state,
  isLoadingDeleteChat: false,
  isErrorOnDeleteChat: true,
  chats: [...state.chats, action.payload]
})

export const deleteChatHandle = { asyncAction: deleteChatAction, request, success, failure }
