import { createStore, applyMiddleware, compose, type Store } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers'
import rootSaga from './rootSaga'

import { createLogger } from 'redux-logger'
import { MessageState } from './message/types'
import { ChatState } from '@/redux/chat/types'
import { UserState } from './user/types'
import { ContactState } from './contact/types'
import { CompanyState } from './company/types'
import { DepartmentState } from './department/types'
import { StickerState } from './sticker/types'

const logger = createLogger({
  collapsed: true,
  logErrors: true,
  diff: true
})

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware, logger] as any

export type ApplicationState = {
  companyReducer: CompanyState
  chatReducer: ChatState
  messageReducer: MessageState
  userReducer: UserState
  contactReducer: ContactState
  departmentReducer: DepartmentState
  stickerReducer: StickerState
}

export function configureStore() {
  const composeEnhancers = compose

  const store: Store<ApplicationState> = createStore(reducers, composeEnhancers(applyMiddleware<any>(...middlewares)))
  sagaMiddleware.run(rootSaga)
  return store
}
