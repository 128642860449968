import { zApiMultiService } from '@/services/api'
import { SendMessageDTO, SendMessageResponse } from './types'

export async function sendMessage({
  campaignId,
  token,
  body,
  documentType = 'send-text',
  extensionFile
}: SendMessageDTO) {
  const extension = documentType === 'send-document' ? extensionFile : ''
  const url = `/instances/campanhaId/${campaignId}/token/${token}/${documentType}/${extension}`

  const response = await zApiMultiService.post<SendMessageResponse>(url, body, {
    headers: {
      'Content-Type': 'application/json'
    }
  })

  return response.data
}
