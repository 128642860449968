import { ActionType, createAsyncAction } from 'typesafe-actions'
import { Failure, Request, Success } from './types'
import { ChatState } from '../../types'

export const cancelChatAction = createAsyncAction(
  '@chat/CANCEL_CHAT_REQUEST',
  '@chat/CANCEL_CHAT_SUCCESS',
  '@chat/CANCEL_CHAT_FAILURE'
)<Request, Success, Failure>()

const request = (state: ChatState): ChatState => ({
  ...state,
  isLoadingCancelChat: true
})

const success = (state: ChatState, action: ActionType<typeof cancelChatAction.success>): ChatState => {
  return {
    ...state,
    inProgressChats: state.inProgressChats.filter(chat => chat.id !== action.payload.chatId),
    isSuccessOnCancelChat: true,
    isLoadingCancelChat: false
  }
}
const failure = (state: ChatState): ChatState => ({
  ...state,
  isLoadingCancelChat: false,
  isErrorOnCancelChat: true
})

export const cancelChatHandle = { asyncAction: cancelChatAction, request, success, failure }
