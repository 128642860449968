import { ActionType, createAction } from 'typesafe-actions'
import { ChatState } from '../../types'
import { DTO } from './types'

export const addMessageOnActiveChatAction = createAction('@chat/ADD_MESSAGE_ON_ACTIVE_CHAT_')<DTO>()

const success = (state: ChatState, action: ActionType<typeof addMessageOnActiveChatAction>): ChatState => {
  const messageToAdd = action.payload
  const updatedMessages = state.activeChat?.messages?.length ? [...state.activeChat?.messages] : []
  const existsMessage = updatedMessages?.some(message => message.messageId === messageToAdd.messageId)
  if (!existsMessage) {
    updatedMessages.unshift(messageToAdd)
  }
  return {
    ...state,
    activeChat: {
      ...state.activeChat,
      messages: updatedMessages
    }
  }
}

export const addMessageOnActiveChatHandle = { asyncAction: addMessageOnActiveChatAction, success }
