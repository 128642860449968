import { put } from 'redux-saga/effects'
import { collection, deleteDoc, doc, getDocs, query, where, writeBatch } from 'firebase/firestore'
import { _fireStore } from '@/helpers/firebase'
import { Chat } from '../../types'
import { deleteChatAction } from '../../handles'

export function* deleteChatByIdSaga(action: ReturnType<typeof deleteChatAction.request>) {
  const chat = action.payload
  try {
    yield put(deleteChatAction.success(chat))
    yield deleteChatAndAssociatedMessages(chat.id, chat.ownerId)
  } catch (error) {
    yield handleDeleteError(error, chat)
  }
}

function* deleteChatAndAssociatedMessages(chatId: string, ownerId: string) {
  yield deleteChatDocument(chatId)
  yield deleteAssociatedMessages(chatId, ownerId)
}

function* deleteChatDocument(chatId: string) {
  const chatDocRef = doc(collection(_fireStore, 'chats'), chatId)
  yield deleteDoc(chatDocRef)
}

function* deleteAssociatedMessages(chatId: string, ownerId: string) {
  const messagesQuery = createMessagesQuery(chatId, ownerId)
  const messagesSnapshot = yield getDocs(messagesQuery)
  const batch = writeBatch(_fireStore)
  messagesSnapshot.docs.forEach(messageDoc => {
    batch.delete(messageDoc.ref)
  })
  yield batch.commit()
}

function createMessagesQuery(chatId: string, ownerId: string) {
  return query(collection(_fireStore, 'messages'), where('chatId', '==', chatId), where('ownerId', '==', ownerId))
}

function* handleDeleteError(error: any, chat: Chat) {
  console.error('Error deleting chat:', error)
  yield put(deleteChatAction.failure(chat))
}
