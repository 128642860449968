import { _fireStore } from '@/helpers/firebase'
import { DocumentData, Query, collection, orderBy, query, where } from 'firebase/firestore'

export function buildUnreadMessagesQuery(chatId: string, ownerId: string): Query<DocumentData, DocumentData> {
  const messagesCollection = collection(_fireStore, `messages`)
  return query(
    messagesCollection,
    where('ownerId', '==', ownerId),
    where('chatId', '==', chatId),
    where('isRead', '==', false),
    orderBy('createdAt', 'desc')
  )
}
