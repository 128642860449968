import { ActionType, createAsyncAction } from 'typesafe-actions'
import { Failure, Request, Success } from './types'
import { ChatState } from '../../types'

export const closeChatAction = createAsyncAction(
  '@chat/CLOSE_CHAT_REQUEST',
  '@chat/CLOSE_CHAT_SUCCESS',
  '@chat/CLOSE_CHAT_FAILURE'
)<Request, Success, Failure>()

const success = (state: ChatState, action: ActionType<typeof closeChatAction.success>): ChatState => ({
  ...state,
  activeChat: { ...state.activeChat, ...action.payload.chat }
})

export const closeChatHandle = { asyncAction: closeChatAction, success }
