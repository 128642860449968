import { _fireStore } from '@/helpers/firebase'
import { User } from '@/models/user.model'
import { updateUserAction } from '@/redux/user/handles'
import { differenceInMilliseconds } from 'date-fns'
import { collection, doc, getDoc, updateDoc } from 'firebase/firestore'
import { put } from 'redux-saga/effects'
import { closeChatAction } from '../../handles/closeChatAction'
import { Chat } from '../../types'
import { onEndServiceHook } from './http/onEndServiceHook'

export function* closeChatSaga(action: ReturnType<typeof closeChatAction.request>) {
  const { chat, userAuthenticated } = action.payload
  try {
    const updatedData: Partial<Chat> = {
      status: 'close',
      startDate: chat.startDate,
      endDate: chat.endDate,
      finishedBy: { id: userAuthenticated.id, name: userAuthenticated.name }
    }
    updatedData.endDate = new Date().getTime()
    updatedData.duration = differenceInMilliseconds(new Date(updatedData.endDate), new Date(updatedData.startDate))
    const user: User = yield findUserById(userAuthenticated.id)
    const updatedAttendFor = user?.attendFor?.filter(campaignId => campaignId !== chat.campaignId) || []

    yield updateChat(chat.id, updatedData)
    yield put(updateUserAction.request({ userId: user.id, userUpdateData: { attendFor: updatedAttendFor } }))
    yield put(closeChatAction.success({ chat: { ...updatedData } }))
    yield onEndServiceHook({ ...chat, ...updatedData })
  } catch (error) {
    console.error('Error when ending chat:', error)
    yield put(closeChatAction.failure(error))
  }
}

async function findUserById(userId: string) {
  const userDocRef = doc(collection(_fireStore, 'users'), userId)
  const userDoc = await getDoc(userDocRef)
  if (!userDoc.exists()) return null
  return userDoc.data() as User
}

async function updateChat(chatId: string, updatedData: Partial<Chat>) {
  const collectionName = `chats`
  const chatDocRef = doc(collection(_fireStore, collectionName), chatId)
  await updateDoc(chatDocRef, updatedData)
}
