import { createAsyncAction } from 'typesafe-actions'
import { ChatState } from '../../types'
import { Failure, Request, Success } from './types'

export const createNewChatAction = createAsyncAction(
  '@chat/CREATE_NEW_CHAT_REQUEST',
  '@chat/CREATE_NEW_CHAT_SUCCESS',
  '@chat/CREATE_NEW_CHAT_FAILURE'
)<Request, Success, Failure>()

const request = (state: ChatState): ChatState => ({
  ...state,
  isLoadingCreateNewChat: true,
  isErrorOnCreateNewChat: false
})

const success = (state: ChatState): ChatState => ({
  ...state,
  isLoadingCreateNewChat: false,
  isErrorOnCreateNewChat: false
})
const failure = (state: ChatState): ChatState => ({
  ...state,
  isLoadingCreateNewChat: false,
  isErrorOnCreateNewChat: true
})

export const createNewChatHandle = { asyncAction: createNewChatAction, request, success, failure }
