import { createReducer } from 'typesafe-actions'
import { ContactAction, ContactState } from './types'
import { createContactHandle } from './handles'

const initialState: ContactState = {
  contacts: [],
  isErrorOnCreateContact: false,
  isLoadingCreateContact: false,
  isSuccessCreateContact: false
}

export const contactReducer = createReducer<ContactState, ContactAction>(initialState)
  .handleAction(createContactHandle.asyncAction.request, createContactHandle.request)
  .handleAction(createContactHandle.asyncAction.success, createContactHandle.success)
  .handleAction(createContactHandle.asyncAction.failure, createContactHandle.failure)
