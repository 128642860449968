import { ActionType, createAction } from 'typesafe-actions'
import { ChatState } from '../../types'
import { DTO } from './types'

export const deleteMessageFromChatAction = createAction('@chat/DELETE_MESSAGE_FROM_CHAT')<DTO>()

const success = (state: ChatState, action: ActionType<typeof deleteMessageFromChatAction>): ChatState => ({
  ...state,
  activeChat: {
    ...state.activeChat,
    messages: state.activeChat.messages?.filter(message => message.messageId !== action.payload.messageId)
  }
})

export const deleteMessageFromChatHandle = { asyncAction: deleteMessageFromChatAction, success }
