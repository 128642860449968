import { _auth, _fireStore } from '@/helpers/firebase'
import { errorMessages } from '@/helpers/form.helpers'
import { isEmpty } from '@/helpers/string.helpers'
import { toast } from '@/helpers/toast/toast.helper'
import { CompanyModel } from '@/models/company.model'
import { User } from '@/models/user.model'
import { UserCredential, signInWithEmailAndPassword } from '@firebase/auth'
import { doc, getDoc } from '@firebase/firestore'
import { put } from 'redux-saga/effects'
import { signInAsync, updateUserAction } from '../../actions'
import { UserAuthenticated } from '../../types'
import { buildDataAccordingUserRole } from './helpers'

export function* signInSaga(action: ReturnType<typeof signInAsync.request>) {
  try {
    const { companyId, email, password, navigate } = action.payload
    if (!companyId || companyId === ':companyId') throw new Error('Empresa não encontrada.')
    const company: CompanyModel = yield findCompanyById(companyId)
    if (isEmpty(company)) throw new Error('Empresa não encontrada.')
    const userCredential: UserCredential = yield signInWithEmailAndPassword(_auth, email, password)
    const user: User = yield findUserById(userCredential.user.uid)
    if (isEmpty(user)) throw new Error('Usuário não encontrado.')
    const userBelongsToCompany = yield isUserBelongsToCompany(companyId, user)
    if (!userBelongsToCompany) throw new Error('Usuário não atende para essa empresa.')
    const isAllowedUser = yield isAllowedUserOnCompany(companyId, user)
    if (!isAllowedUser) throw new Error('Acesso não autorizado.')

    const { userAttendCampaigns, userDepartments } = yield buildDataAccordingUserRole(user, companyId)

    const userAuthenticated: UserAuthenticated = {
      id: user.id,
      name: user.name,
      email: user.email,
      companies: user.companies,
      attendCampaigns: userAttendCampaigns,
      ownerId: user.ownerId,
      isAdmin: user.companies?.some(
        company => company.id === companyId && (company.role.includes('ADMIN') || company.role.includes('MASTER'))
      ),
      role: user.companies?.find(company => company.id === companyId)?.role[0] || null,
      departments: userDepartments,
      isWork: true
    }
    localStorage.setItem('devchat_app_userAuthenticated', JSON.stringify(userAuthenticated))
    yield put(updateUserAction.request({ userId: user.id, userUpdateData: { isWork: true } }))
    yield put(signInAsync.success({ userAuthenticated }))
    yield navigate(`/select-department/${companyId}`)
  } catch (error) {
    if (error.code) {
      yield toast({ type: 'error', message: errorMessages[error.code] })
      yield put(signInAsync.failure({ message: error.code }))
    } else {
      yield toast({ type: 'error', message: 'Algo deu errado, por favor tente novamente mais tarde.' })
      yield put(signInAsync.failure({ message: error.message }))
    }
  }
}

async function findCompanyById(id: string) {
  const collectionName = 'companies'
  const docRef = doc(_fireStore, collectionName, id)
  const docData = await getDoc(docRef)
  if (!docData.exists()) return null
  return docData.data() as User
}

async function findUserById(id: string) {
  const collectionName = 'users'
  const docRef = doc(_fireStore, collectionName, id)
  const docData = await getDoc(docRef)
  if (!docData.exists()) return null
  return docData.data() as User
}

export async function isUserBelongsToCompany(companyId: string, user: User) {
  const { companies } = user
  if (!companies.length) return false
  return companies.some(company => company.id === companyId)
}

async function isAllowedUserOnCompany(companyId: string, user: User) {
  const { companies } = user
  if (!companies.length) return false
  const company = companies.find(company => company.id === companyId)
  if (!company) return false
  if (!company?.role) return false
  return company?.role.includes('ADMIN') || company?.role.includes('OPERATOR') || company?.role.includes('MASTER')
}
