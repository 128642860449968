import { _fireStore } from '@/helpers/firebase'
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore'
import { call, put } from 'redux-saga/effects'
import { createNewChatAction, setActiveChatAction } from '../../handles'
import { isEmpty } from '@/helpers/string.helpers'
import { CreateMessageRequestParam } from '@/redux/message/actions'
import { SendMessageResponse } from '@/redux/message/sagas/createMessageSaga/http/message/types'
import { sendMessage } from '@/redux/message/sagas/createMessageSaga/http/message'
import { v4 as uuid } from 'uuid'
import { Chat } from '../../types'
import { getFullDate } from '@/helpers/date.helpers'
import { toast } from '@/helpers/toast/toast.helper'
import { SelectedDepartmentModel } from '@/models/department.model'
import { generateSixDigitTicketNumber } from '@/redux/message/utils'
import { MessageModel } from '@/models/message.model'

export function* createNewChatSaga(action: ReturnType<typeof createNewChatAction.request>) {
  const { message, ownerId, campaignId, token, callBack, department } = action.payload
  try {
    const newMessage = yield call(buildNewMessage, message)
    const chat = yield call(
      getChatByNumber,
      newMessage.senderNumber.toString(),
      newMessage.operator.id,
      ownerId,
      campaignId,
      ['open', 'in_progress']
    )
    if (isEmpty(chat)) {
      const sendMessageResult: SendMessageResponse = yield call(
        sendMessageToZApiMultiService,
        newMessage,
        campaignId,
        token
      )
      newMessage.messageId = sendMessageResult.messageId
      if (newMessage.messageId) newMessage.isSend = true
      const newChat = yield call(buildNewChat, { message: newMessage, ownerId, department })
      newMessage.chatId = newChat.id
      yield call(createNewMessageOnFirestore, newMessage)
      yield call(createNewChat, newChat)
      yield put(setActiveChatAction({ ...newChat, unreadMessagesCount: 0 }))
      yield put(createNewChatAction.success())
      callBack()
    } else {
      toast({ type: 'error', message: 'Já existe um chat para esse número ou número inválido' })
      throw new Error('Já existe um chat para esse número')
    }
  } catch (error) {
    yield put(createNewChatAction.failure(error))
  }
}

async function buildNewMessage(message: CreateMessageRequestParam['message']) {
  return {
    ...message,
    messageId: '',
    isRead: false,
    isSend: false,
    fromMe: true,
    createdAt: new Date().getTime(),
    file: null
  }
}

async function sendMessageToZApiMultiService(newMessage: MessageModel, campaignId: string, token: string) {
  if (newMessage.messageText.trim()) {
    return await sendMessage({
      campaignId,
      token,
      body: {
        phone: newMessage.phone,
        message: newMessage.messageText
      }
    })
  }
}

type BuildNewChat = {
  message: MessageModel
  ownerId: string
  department: SelectedDepartmentModel
}

async function buildNewChat({ message, ownerId, department }: BuildNewChat): Promise<Partial<Chat>> {
  const ticket = generateSixDigitTicketNumber()
  const baseDate = new Date()
  const dateNow = baseDate.getTime()
  const dateMonth = baseDate.getMonth()
  return {
    id: uuid(),
    department: { id: department?.id, name: department?.name },
    departmentId: department?.id,
    ownerId,
    campaignId: message.campanhaId,
    instanceId: message.instanceId,
    status: 'in_progress',
    unreadMessagesCount: 0,
    receiverNumber: message.receiverNumber.toString(),
    requesterNumber: message.senderNumber.toString(),
    chatName: message.senderName || message.senderNumber.toString(),
    operatorsId: [message.operator.id],
    operators: [message.operator],
    startDate: dateNow,
    endDate: null,
    month: dateMonth,
    year: baseDate.getFullYear(),
    fullDate: getFullDate(baseDate),
    duration: 0,
    ticket,
    lastMessage: message as any,
    updatedAt: dateNow,
    createdAt: dateNow
  }
}

async function createNewChat(chat: Chat) {
  const _collectionName = `chats`
  const newChatDoc = doc(collection(_fireStore, _collectionName), chat.id)
  await setDoc(newChatDoc, chat)
}

async function createNewMessageOnFirestore(message: MessageModel) {
  const collectionName = `messages`
  const newMessageDoc = doc(collection(_fireStore, collectionName), message.messageId)
  await setDoc(newMessageDoc, {
    ...message,
    receiverNumber: message.senderNumber.toString(),
    senderNumber: message.receiverNumber.toString()
  })
}

async function getChatByNumber(
  senderNumber: string,
  operatorId: string,
  ownerId: string,
  campaignId: string,
  status: string[]
) {
  const _collectionName = `chats`
  const _query = query(
    collection(_fireStore, _collectionName),
    where('ownerId', '==', ownerId),
    where('operatorsId', 'array-contains', operatorId),
    where('campaignId', '==', campaignId),
    where('requesterNumber', '==', senderNumber),
    where('status', 'in', status)
  )
  const chatDocs = await getDocs(_query)
  if (chatDocs.empty) return null
  return chatDocs.docs.map(doc => doc.data())[0]
}
