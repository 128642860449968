import { ActionType, createAction } from 'typesafe-actions'
import { DTO } from './types'
import { ChatState } from '../../types'
import { addChatInChatsState } from '../helpers'

export const addArchiveChatAction = createAction('@chat/ADD_ARCHIVE_CHAT')<DTO>()

const success = (state: ChatState, action: ActionType<typeof addArchiveChatAction>): ChatState => ({
  ...state,
  archiveChats: addChatInChatsState(state.archiveChats, action.payload)
})

export const addArchiveChatHandle = { asyncAction: addArchiveChatAction, success }
