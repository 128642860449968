export const errorMessages = {
  required: 'Campo obrigatório',
  'invalid-number': 'Número inválido',
  'auth/wrong-password': 'Email ou senha inválidos.',
  'auth/too-many-requests':
    'Você fez muitas tentativas em um curto período de tempo. Por favor, aguarde um momento antes de tentar novamente.',
  'auth/user-not-found': 'Usuário não encontrado.',
  'auth/email-already-in-use': 'Este e-mail já está em uso.',
  'auth/weak-password': 'A senha deve ter pelo menos 6 caracteres.',
  contactAlreadyExists: 'Contato já existente'
}

// TODO: move this to a own file
/**
 * Each field can have multiple errors.
 */
export type FormErrorFields<T> = Record<keyof T, Array<typeof errorMessages>>

// Form
export type ValidationForm<T> = {
  onSubmit: (data: T) => void
  errorFields?: FormErrorFields<T>
  defaultData?: Partial<T>
}
