import { MessageModel } from '@/models/message.model'
import { compareAsc, format, intervalToDuration, isToday, isYesterday } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { ptBR } from 'date-fns/locale'

export const forceTimezone = 'America/Sao_Paulo'

/**
 * Formats a date returned by Firebase according to the provided rules.
 * If the date is today, the format will be HH:mm.
 * If the date is yesterday, the format will be 'Yesterday'.
 * If the date is after yesterday, the format will be d/MM/yy.
 *
 * @param date - Long date.
 * @returns The formatted date according to the specified rules.
 */

export function formatDateToChat(date: any) {
  if (!date) return ''
  const newDate = new Date(date)

  if (isToday(newDate)) {
    const formattedDate = format(newDate, 'HH:mm')
    return formattedDate
  } else if (isYesterday(newDate)) {
    return 'Ontem'
  } else {
    const formattedDate = format(newDate, 'd/MM/yy')
    return formattedDate
  }
}

/**
 * Compare function to sort messages by their createdAt property.
 * @param messageA - The first message.
 * @param messageB - The second message.
 * @returns A negative value if messageA should be sorted before messageB,
 *          a positive value if messageA should be sorted after messageB,
 *          or 0 if both messages have the same sort order.
 */
export function compareMessagesByCreatedAt(messageA: MessageModel, messageB: MessageModel): number {
  const dateA = new Date(messageA.createdAt)
  const dateB = new Date(messageB.createdAt)

  return compareAsc(dateA, dateB)
}

export function formatDuration(durationInMilliseconds: number) {
  const duration = intervalToDuration({ start: 0, end: durationInMilliseconds })
  const formattedElapsedTime = `${String(duration.hours).padStart(2, '0')}:${String(duration.minutes).padStart(
    2,
    '0'
  )}:${String(duration.seconds).padStart(2, '0')}`
  return formattedElapsedTime
}

export function getFullDate(date: string | number | Date) {
  if (!date) return ''
  if (typeof date === 'string') {
    date = new Date(date)
  }
  if (typeof date === 'number') {
    date = new Date(date)
  }
  return format(date, 'dd/MM/yyyy')
}

/**
 * Receives a Date Object and returns a string with the date in the format DD/MM/YYYY
 * @param date
 */
export function convertDateToString(date: Date) {
  return formatInTimeZone(date, forceTimezone, 'dd/MM/yyyy', { locale: ptBR })
}

export function formatTimestampToDateString(timestamp: number, timeZone = forceTimezone) {
  const date = new Date(timestamp)
  return formatInTimeZone(date, timeZone, "dd/MM/yyyy 'às' HH:mm", { locale: ptBR })
}

export function formatDurationFromTimestamp(timestamp) {
  const duration = intervalToDuration({ start: 0, end: timestamp })

  if (duration.hours > 0) {
    return `${duration.hours} ${duration.hours === 1 ? 'hora' : 'horas'}`
  } else if (duration.minutes > 0) {
    const minutesPart = `${duration.minutes} ${duration.minutes === 1 ? 'min' : 'min'}`
    if (duration.seconds > 0) {
      return `${minutesPart} e ${duration.seconds} ${duration.seconds === 1 ? 'seg' : 'seg'}`
    }
    return minutesPart
  } else if (duration.seconds > 0) {
    return `${duration.seconds} ${duration.seconds === 1 ? 'seg' : 'seg'}`
  } else {
    return '0'
  }
}
