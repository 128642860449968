import { createAction, createAsyncAction } from 'typesafe-actions'
import { UserAuthenticated } from './types'
import { NavigateFunction } from 'react-router'
export { updateUserAction } from './handles'

export const saveAuthUser = createAction('@user/SAVE_AUTH_USER_SUCCESS')<UserAuthenticated>()

type SignInParams = {
  companyId: string
  email: string
  password: string
  navigate: NavigateFunction
}

export const signInAsync = createAsyncAction('@user/SIGN_IN_REQUEST', '@user/SIGN_IN_SUCCESS', '@user/SIGN_IN_FAILURE')<
  SignInParams,
  { userAuthenticated: UserAuthenticated },
  { message: string }
>()

export const signOutAsync = createAsyncAction(
  '@user/SIGN_OUT_REQUEST',
  '@user/SIGN_OUT_SUCCESS',
  '@user/SIGN_OUT_FAILURE'
)<UserAuthenticated | null, void, void>()
