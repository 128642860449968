import { doc, updateDoc } from 'firebase/firestore'
import { _fireStore } from '@/helpers/firebase'
import { updateUserAction } from '../../handles'
import { put } from 'redux-saga/effects'

export function* updateUserSaga(action: ReturnType<typeof updateUserAction.request>) {
  try {
    const { userId, userUpdateData } = action.payload
    const userDoc = doc(_fireStore, 'users', userId)
    yield updateDoc(userDoc, userUpdateData)
    yield put(updateUserAction.success({ user: null }))
  } catch (error) {
    console.log('updateUserSaga Error', error)
    yield put(updateUserAction.failure())
  }
}
