import { isUserBelongsToCompany } from '@/redux/user/sagas/signInSaga'
import { put } from 'redux-saga/effects'
import { setSelectedCompanyAction } from '../../handles/setSelectedCompanyAction'
import { signOutAsync } from '@/redux/user/actions'

export function* setSelectedCompanySaga(action: ReturnType<typeof setSelectedCompanyAction.request>) {
  const { selectedCompany, user, navigate } = action.payload
  try {
    const userBelongsToCompany = yield isUserBelongsToCompany(selectedCompany, user as any)
    if (!userBelongsToCompany) throw new Error('Usuário não atende para essa empresa.')
    yield put(setSelectedCompanyAction.success({ selectedCompany }))
  } catch (error) {
    yield put(setSelectedCompanyAction.failure(error))
    yield put(signOutAsync.request(user))
    yield navigate('/')
  }
}
