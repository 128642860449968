import { isObjectEmpty } from './object.helpers'

/**
 * Verifica se um elemento está vazio.
 * @param {*} element - O elemento a ser verificado.
 * @return {boolean} Retorna true se o elemento estiver vazio, caso contrário, retorna false.
 */
export function isEmpty(element) {
  return (
    element === '' ||
    element === undefined ||
    element === null ||
    element === 'undefined' ||
    isObjectEmpty(element) ||
    /^\s*$/.test(element)
  )
}

export function boldText(text: string) {
  if (typeof text !== 'string') return text
  return text.replace(/\*(.*?)\*/g, '<b>$1</b>')
}
