import { setHasMoreMessages } from '@/redux/message/actions'
import { getDocs } from 'firebase/firestore'
import { call, put } from 'redux-saga/effects'

import { countDocumentsInQuery, lastMessagesByChatQuery } from './helpers'
import { loadMessagesByChatIdAction } from '../../handles'
import { MessageModel } from '@/models/message.model'

const MESSAGES_LIMIT = 12
const COUNT_QUERY_LIMIT = 13

export function* loadMessagesByChatIdSaga(action: ReturnType<typeof loadMessagesByChatIdAction.request>) {
  const { chatId, ownerId } = action.payload
  try {
    const lastMessages = yield call(fetchLastMessages, chatId, ownerId)
    const hasMoreMessages = yield checkMoreMessagesAvailability(chatId, ownerId)
    yield put(setHasMoreMessages({ state: hasMoreMessages }))
    yield put(loadMessagesByChatIdAction.success(lastMessages))
  } catch (error) {
    yield put(loadMessagesByChatIdAction.failure(error))
  }
}

function* fetchLastMessages(chatId: string, ownerId: string) {
  const messagesQuery = lastMessagesByChatQuery(chatId, ownerId, MESSAGES_LIMIT)
  const messagesQuerySnapshot = yield call(getDocs, messagesQuery)
  return messagesQuerySnapshot.docs.map(doc => doc.data() as MessageModel)
}

// TODO: move to a generic helper, because we are using this on two places
function* checkMoreMessagesAvailability(chatId: string, ownerId: string) {
  const lastMessagesQuery = lastMessagesByChatQuery(chatId, ownerId, COUNT_QUERY_LIMIT)
  const documentsCount = yield countDocumentsInQuery(lastMessagesQuery)
  return documentsCount >= COUNT_QUERY_LIMIT
}
